import styled from 'styled-components'
import { SimpleLabelStyled } from '../SimpleLabel/SimpleLabel.styles'
import { portalTheme } from '../..'

export const CustomCheckboxRadioStyled = styled(SimpleLabelStyled)`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: start;
  input {
    width: 0px !important;
    height: 0px !important;
    visibility: hidden;
    display: none;
  }
  .icon {
    padding-right: 1em;
    color: ${(props) =>
      props?.theme?.components?.customCheckboxRadio?.icon?.color ||
      portalTheme.color.linkColorSecondaryActive};
    &.disabled {
      opacity: 1;
    }
  }
  user-select: none;

  ${(props) => props?.theme?.components?.customCheckboxRadio?.additionalStyles}
  ${(props) =>
    props.isChecked &&
    props?.theme?.components?.customCheckboxRadio?.additionalStylesWhenChecked}
`

export const StyledFlexDiv = styled.div`
  display: flex;
  flex-direction: row;

  .filler-wrapper {
    display: inline-block;
    flex: 1;
  }
`

import styled from 'styled-components'
import React from 'react'
import PropTypes from 'prop-types'
import parse from 'html-react-parser'
import { portalTheme } from '../../../styles/PortalTheme'
import { Col, Row } from 'react-grid-system'
import Icon from '../../Icon/Icon'
import { buttonRemoveBrowserStylesCss } from '../../../styles/helperStyles'

const LetterContentStyled = styled.div`
  border-radius: 5px;
  //padding: 1mm;

  ${(props) =>
    !props.formError
      ? !props.previewShow && props.editable
        ? 'background-color: #F0F0F0;'
        : props.adminEdit
        ? props.editable
          ? 'background-color: #F0F0F0;'
          : props.active
          ? 'background-color: #8AA8C8; border: 2px solid #6fc4e9;'
          : 'background-color: #DDF5FF; border: 2px solid #6fc4e9;'
        : props.editable
        ? props.active
          ? 'background-color: #8AA8C8; border: 2px solid #6fc4e9;'
          : 'background-color: #DDF5FF; border: 2px solid #6fc4e9;'
        : 'background-color: #FFFFF; border-radius: 0px;'
      : props.adminEdit
      ? props.editable
        ? 'background-color: #F0F0F0;'
        : props.active
        ? 'background-color: #8AA8C8; border: 2px solid ' +
          portalTheme.color.errorColor +
          ';'
        : 'background-color: #DDF5FF; border: 2px solid ' +
          portalTheme.color.errorColor +
          ';'
      : props.editable
      ? props.active
        ? 'background-color: #8AA8C8; border: 2px solid ' +
          portalTheme.color.errorColor +
          ';'
        : 'background-color: #DDF5FF; border: 2px solid ' +
          portalTheme.color.errorColor +
          ';'
      : 'background-color: #FFFFF; border-radius: 0px;'}
`

const ContainerTitle = styled.div`
  position: absolute;
`

export const LinkButtonStyled = styled.button`
  background-color: transparent;
  border: none;
  display: flex;
  padding: 0px;
  align-items: center;
  color: ${portalTheme.color.linkColorPrimary};
  text-decoration: none;
  font-family: ${portalTheme.font.family.quicksand};
  ${buttonRemoveBrowserStylesCss}
`

const LetterContentStatic = (props) => {
  const {
    onClick = () => {},
    title = '',
    titleStyles = {},
    previewShow,
    previewStyle = {},
    active = false,
    content = '',
    contentType,
    customRenderer,
    formError = false,
    generateValueUnitDivided,
    editable = false,
    adminEdit = false
  } = props

  const generateContent = (content) => {
    if (customRenderer) {
      content = customRenderer(content)
    }
    let parsedElement = parse(
      content.replace(/<p[^>]*>/g, '').replace(/<\/p>/g, '<br/ >')
    )
    if (
      typeof parsedElement !== 'object' ||
      Array.isArray(parsedElement) ||
      parsedElement === null
    ) {
      parsedElement = parse(
        '<div>' +
          content.replace(/<p[^>]*>/g, '').replace(/<\/p>/g, '<br/ >') +
          '</div>'
      )
    }
    let returnElement = ''
    if (
      typeof parsedElement === 'object' &&
      parsedElement !== null &&
      !Array.isArray(parsedElement)
    ) {
      const calculatedStyles = parsedElement?.props?.style
        ? Object.keys(parsedElement?.props?.style).reduce((acc, key) => {
            acc[key] = generateValueUnitDivided(
              parsedElement?.props?.style[key]
            )
            return acc
          }, {})
        : {}
      const calculatedElement = {
        ...parsedElement,
        props: {
          ...parsedElement.props,
          style: calculatedStyles
        }
      }
      returnElement = calculatedElement
    } else {
      returnElement = content
    }
    switch (contentType) {
      case 'img':
        return returnElement
      default:
        // return returnElement
        return !adminEdit && !editable ? returnElement : ''
    }
  }
  const getCalculateTitleStyleTopBottom = () => {
    let calc = ''
    if (previewStyle?.top) {
      if (titleStyles?.top) {
        calc = {
          top: 'calc(' + previewStyle?.top + ' - ' + titleStyles?.top + ')'
        }
      } else {
        calc = {
          top:
            'calc(' +
            previewStyle?.top +
            ' + ' +
            previewStyle?.height +
            ' + ' +
            titleStyles?.bottom +
            ')'
        }
      }
    } else {
      if (titleStyles?.top) {
        calc = {
          bottom:
            'calc(' +
            previewStyle?.bottom +
            ' + ' +
            previewStyle?.height +
            ' + ' +
            titleStyles?.top +
            ')'
        }
      } else {
        calc = {
          bottom:
            'calc(' + previewStyle?.bottom + ' - ' + titleStyles?.bottom + ')'
        }
      }
    }
    return calc
  }

  const getCalculateTitleStyleLeftRight = () => {
    let calc = ''
    if (previewStyle?.left) {
      calc = {
        left: previewStyle?.left ? previewStyle?.left : '0'
      }
    } else {
      if (titleStyles?.right) {
        calc = { right: titleStyles?.right }
      } else {
        calc = {
          left:
            'calc(' +
            '100%' +
            ' - ' +
            (previewStyle?.width ?? previewStyle?.maxWidth) +
            ')'
        }
      }
    }
    return calc
  }

  let tmpStyles = {}
  if (previewStyle?.maxWidth && adminEdit) {
    tmpStyles = { ...previewStyle, width: previewStyle?.maxWidth }
  } else {
    tmpStyles = previewStyle
  }

  return (
    <>
      {(adminEdit && !editable && previewShow) ||
      (!adminEdit && editable && previewShow) ? (
        <ContainerTitle
          style={{
            ...getCalculateTitleStyleTopBottom(),
            ...getCalculateTitleStyleLeftRight(),
            fontSIze: titleStyles?.fontSize
          }}
        >
          <LinkButtonStyled onClick={onClick}>
            <Row nogutter justify='center' align='center'>
              {formError && (
                <Col
                  xs='content'
                  style={{
                    paddingLeft: '5px',
                    fontSize: titleStyles?.fontSize
                  }}
                >
                  <Icon
                    icon='info'
                    size={titleStyles?.fontSize}
                    style={{
                      color: portalTheme.color.errorColor
                    }}
                    aria-hidden='true'
                  />
                </Col>
              )}
              <Col
                xs='content'
                style={{
                  paddingLeft: '5px',
                  fontSize: titleStyles?.fontSize
                }}
              >
                {title}
              </Col>
              <Col
                style={{
                  paddingLeft: '5px',
                  display: 'flex',
                  justifyItems: 'center',
                  alignItems: 'center'
                }}
              >
                <Icon
                  icon='arrow-right'
                  size={'calc(' + titleStyles?.fontSize + ' / 1.5)'}
                  aria-hidden='true'
                />
              </Col>
            </Row>
          </LinkButtonStyled>
        </ContainerTitle>
      ) : (
        ''
      )}
      <LetterContentStyled
        formError={formError}
        onClick={onClick}
        previewShow={previewShow}
        active={active ? active.toString() : undefined}
        editable={editable ? editable.toString() : undefined}
        adminEdit={adminEdit ? adminEdit.toString() : undefined}
        style={tmpStyles}
      >
        {generateContent(content)}
      </LetterContentStyled>
    </>
  )
}

LetterContentStatic.propTypes = {
  startWidth: PropTypes.any
}
export default LetterContentStatic

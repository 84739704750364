import React from 'react'
import { StyledSpinner } from './Spinner.styles'
import PropTypes from 'prop-types'

const Spinner = (props) => {
  return <StyledSpinner theme={props.theme} className={props.className} />
}

Spinner.propTypes = {
  theme: PropTypes.shape({
    components: PropTypes.shape({
      spinner: PropTypes.shape({
        /**
         * Has to be a { css } object from styled-components
         */
        additionalStyles: PropTypes.array,
        size: PropTypes.string,
        border: PropTypes.string
      })
    })
  })
}

export default Spinner

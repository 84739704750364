import React, { useState } from 'react'
import { Col, Row } from 'react-grid-system'
import PropTypes from 'prop-types'

import Icon from '../Icon/Icon'

import {
  IconCol,
  PulldownValues,
  PulldownValuesFixedCol,
  SimplePullDownStyled
} from './SimplePullDown.styles'
import { portalTheme } from '../../styles/PortalTheme'

const SimplePullDown = (props) => {
  const { isOpen, headerLabelElement, values, theme, className, onClick } =
    props

  const [isPullDownOpen, setPullDownOpen] = useState(isOpen)

  return (
    <SimplePullDownStyled
      theme={theme}
      className={className}
      onClick={onClick}
      data-simple-pulldown=''
    >
      <Row
        justify='between'
        onClick={() => setPullDownOpen(!isPullDownOpen)}
        align='center'
        nogutter
        data-simple-pulldown-header-wrapper=''
      >
        <Col data-simple-pulldown-header=''>{headerLabelElement}</Col>
        <IconCol xs='content'>
          <Icon
            icon={isPullDownOpen ? 'hide' : 'expand'}
            size={theme.components.simplePullDown.icon.fontSize}
            aria-hidden='true'
            data-simple-pulldown-header-icon=''
          />
        </IconCol>
      </Row>

      <Row nogutter data-simple-pulldown-body-wrapper=''>
        <PulldownValuesFixedCol>
          <PulldownValues
            isOpen={isPullDownOpen}
            theme={theme}
            data-simple-pulldown-body=''
          >
            {isPullDownOpen && values}
          </PulldownValues>
        </PulldownValuesFixedCol>
      </Row>
    </SimplePullDownStyled>
  )
}

export default SimplePullDown

SimplePullDown.propTypes = {
  isOpen: PropTypes.bool,
  values: PropTypes.any.isRequired,
  headerLabelElement: PropTypes.any.isRequired,
  theme: PropTypes.shape({
    components: PropTypes.shape({
      simplePullDown: PropTypes.shape({
        /**
         * Has to be a { css } oject from styled-components
         */
        additionalStyles: PropTypes.array,
        fontFamily: PropTypes.string,
        fontSize: PropTypes.string,
        color: PropTypes.string,
        simplePullDownValues: PropTypes.shape({
          fontFamily: PropTypes.string,
          fontSize: PropTypes.string
        }),
        simplePullDownIcon: PropTypes.shape({
          fontSize: PropTypes.string
        })
      })
    })
  })
}

SimplePullDown.defaultProps = {
  isOpen: false,
  theme: {
    components: {
      simplePullDown: {
        fontFamily: portalTheme.font.family.quicksand,
        fontSize: '20px',
        fontWeight: portalTheme.font.weight.bold,
        color: portalTheme.color.basicColorWhite,
        simplePullDownValuesRow: {
          fontFamily: portalTheme.font.family.opensans,
          fontSize: '14px',
          fontWeight: portalTheme.font.weight.regular
        },
        icon: {
          fontSize: '40px'
        }
      }
    }
  }
}

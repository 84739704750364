import { datadogRum } from '@datadog/browser-rum'
export const init = (
  applicationId,
  clientToken,
  site,
  service,
  env,
  version,
  sampleRate,
  premiumSampleRate,
  trackInteractions
) => {
  datadogRum.init({
    applicationId,
    clientToken,
    site,
    service,
    env,
    version,
    sampleRate,
    premiumSampleRate,
    trackInteractions
  })
}

import styled, { css } from 'styled-components'
import { portalTheme } from '../../styles/PortalTheme'

export const simpleLinkCSS = css`
  font-family: ${(props) =>
    props?.theme?.components?.simpleLink?.fontFamily ||
    portalTheme.font.family.quicksand};
  font-size: ${(props) =>
    props?.theme?.components?.simpleLink?.fontSize || '16px'};
  font-weight: ${(props) =>
    props?.theme?.components?.simpleLink?.fontWeight ||
    portalTheme.font.weight.regular};

  cursor: pointer;
  background-color: transparent;
  text-decoration: none;

  color: ${(props) =>
    props?.theme?.components?.simpleLink?.color ||
    portalTheme.color.linkColorPrimary};

  &:hover,
  &.test--hover,
  &:focus,
  &.test--focus {
    color: ${(props) =>
      props?.theme?.components?.simpleLink?.focus?.color ||
      portalTheme.color.linkColorPrimaryHover};
    text-decoration: none;
  }

  &:active,
  &.test--active {
    color: ${(props) =>
      props?.theme?.components?.simpleLink?.active?.color ||
      portalTheme.color.linkColorPrimaryActive};
    text-decoration: none;
  }
`
export const SimpleLinkStyled = styled.a`
  ${simpleLinkCSS}
  ${(props) => props.theme.components.simpleLink.additionalStyles}
`

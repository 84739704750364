import React from 'react'
import PropTypes from 'prop-types'

import { SimpleLinkStyled } from './SimpleLink.styles'
import { css } from 'styled-components'

const SimpleLink = (props) => {
  const { target, url, onClick, label } = props
  return (
    <SimpleLinkStyled
      theme={props.theme}
      target={target}
      href={url}
      onClick={(e) => onClick !== undefined && onClick(e)}
    >
      {label}
    </SimpleLinkStyled>
  )
}

export default SimpleLink

SimpleLink.propTypes = {
  target: PropTypes.string,
  disableDefaultCssClass: PropTypes.string,
  url: PropTypes.string,
  onClick: PropTypes.func,
  label: PropTypes.node,
  theme: PropTypes.shape({
    components: PropTypes.shape({
      simpleLink: PropTypes.shape({
        /**
         * Has to be a { css } from styled-components
         */
        additionalStyles: PropTypes.array
      })
    })
  })
}

SimpleLink.defaultProps = {
  target: '_blank',
  disableDefaultCssClass: '',
  url: '/',
  label: '',
  theme: {
    components: {
      simpleLink: {
        additionalStyles: css``
      }
    }
  }
}

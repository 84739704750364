import React from 'react'
import PropTypes from 'prop-types'
import { SimpleLabelStyled } from './SimpleLabel.styles'

const SimpleLabel = (props) => {
  return (
    <SimpleLabelStyled className={props.className} theme={props.theme}>
      {props.children}
    </SimpleLabelStyled>
  )
}

export default SimpleLabel

SimpleLabel.propTypes = {
  children: PropTypes.node,
  theme: PropTypes.shape({
    components: PropTypes.shape({
      simpleLabel: PropTypes.shape({
        additionalStyles: PropTypes.array,
        fontSize: PropTypes.string,
        fontWeight: PropTypes.string,
        color: PropTypes.string,
        fontFamily: PropTypes.string
      })
    })
  })
}

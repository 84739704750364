import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import { ButtonFlag } from '../Button'

const StyledLangSwitcher = styled.span`
  text-align: right;
`
const LangSwitcher = (props) => {
  const { availableLanguages, currentLanguage, changeLanguage } = props

  return (
    <StyledLangSwitcher>
      {availableLanguages.map((language) => (
        <ButtonFlag
          key={language}
          language={language}
          changeLanguage={changeLanguage}
          isActive={currentLanguage === language}
          mode='header'
        />
      ))}
    </StyledLangSwitcher>
  )
}

export default LangSwitcher

LangSwitcher.propTypes = {
  availableLanguages: PropTypes.arrayOf(PropTypes.string).isRequired,
  currentLanguage: PropTypes.string.isRequired,
  changeLanguage: PropTypes.func.isRequired
}

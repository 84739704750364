import deActive from './img/de-active.svg'
import deInactive from './img/de-inactive.svg'
import enActive from './img/en-active.svg'
import enInactive from './img/en-inactive.svg'

const flagSelector = {
  de: {
    true: deActive,
    false: deInactive
  },
  en: {
    true: enActive,
    false: enInactive
  }
}

const srcSelector = (lang, condition, mode) => {
  // There are not inactive flags when using them as a modal (LangSwitcherModal).
  if (mode === 'modal') return flagSelector?.[lang]?.true

  return flagSelector?.[lang]?.[condition]
}

export default srcSelector

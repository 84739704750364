import React, { useState } from 'react'
import { Col, Row } from 'react-grid-system'
import { MobilePullDownStyled } from './MobilePullDown.styles'
import { portalTheme } from '../../styles/PortalTheme'
import PropTypes from 'prop-types'
import Icon from '../Icon/Icon'

const MobilePullDown = (props) => {
  const [mobilePullDownOpen, setMobilePullDownOpen] = useState(
    props.isCollapsed
  )
  return (
    <MobilePullDownStyled theme={props.theme}>
      <Row
        justify='between'
        onClick={() => setMobilePullDownOpen(!mobilePullDownOpen)}
        align='center'
      >
        <Col xs='content' md='content' lg='content'>
          {props.headerLabel}
        </Col>
        <Col
          xs='content'
          md='content'
          lg='content'
          className='mobile-pull-down-icon-container'
        >
          <Icon
            icon={mobilePullDownOpen ? 'hide' : 'expand'}
            size={
              props.theme.components.mobilePullDown.mobilePullDownIcon.fontSize
            }
            aria-hidden='true'
          />
        </Col>
      </Row>
      <Row component='ul' className={`${mobilePullDownOpen && 'open'}`}>
        {props.values.map((value, index) => (
          <Col
            component='li'
            xs={12}
            md={12}
            lg={12}
            key={props.headerLabel + '-' + index}
          >
            {value}
          </Col>
        ))}
      </Row>
    </MobilePullDownStyled>
  )
}

export default MobilePullDown

MobilePullDown.defaultProps = {
  isCollapsed: false,
  theme: {
    components: {
      mobilePullDown: {
        fontFamily: portalTheme.font.family.quicksand,
        fontSize: '20px',
        fontWeight: portalTheme.font.weight.bold,
        color: portalTheme.color.basicColorWhite,
        borderBottomColor: portalTheme.color.basicColorWhite,
        mobilePullDownValuesRow: {
          fontFamily: portalTheme.font.family.opensans,
          fontSize: '14px',
          fontWeight: portalTheme.font.weight.regular
        },
        mobilePullDownIcon: {
          fontSize: '40px'
        }
      }
    }
  }
}

MobilePullDown.propTypes = {
  headerLabel: PropTypes.string.isRequired,
  values: PropTypes.arrayOf(PropTypes.any).isRequired,
  isCollapsed: PropTypes.bool,
  theme: PropTypes.shape({
    components: PropTypes.shape({
      mobilePullDown: PropTypes.shape({
        /**
         * Has to be a { css } oject from styled-components
         */
        additionalStyles: PropTypes.array,
        fontFamily: PropTypes.string,
        fontSize: PropTypes.string,
        color: PropTypes.string,
        borderBottomColor: PropTypes.string,
        mobilePullDownValues: PropTypes.shape({
          fontFamily: PropTypes.string,
          fontSize: PropTypes.string
        }),
        mobilePullDownIcon: PropTypes.shape({
          fontSize: PropTypes.string
        })
      })
    })
  })
}

import React from 'react'
import PropTypes from 'prop-types'

import {
  Content,
  StyledButtonPrimary,
  StyledSpinner
} from './ButtonPrimary.styles'

const ButtonPrimary = (props) => {
  const {
    children,
    isLoading = false,
    disabled = false,
    className,
    ...restOfProps
  } = props

  return (
    <StyledButtonPrimary
      disabled={disabled}
      className={className}
      {...restOfProps}
    >
      <Content hide={isLoading}>{children}</Content>

      {isLoading && <StyledSpinner />}
    </StyledButtonPrimary>
  )
}

export default ButtonPrimary

ButtonPrimary.propTypes = {
  children: PropTypes.any,
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.any
}

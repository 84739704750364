import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import SimpleModal from '../SimpleModal/SimpleModal'
import PropTypes from 'prop-types'
import { Col, Row } from 'react-grid-system'
import { PDFDocument } from 'pdf-lib'
import { ButtonSecondary } from '../Button'
import Icon from '../Icon/Icon'
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner'
import { portalTheme } from '../../styles/PortalTheme'
import SimpleNote from '../SimpleNote/SimpleNote'

export const StyledModal = styled(SimpleModal)`
  z-index: 9999988;

  & [data-simple-modal-content] {
    height: 80vh;
    max-height: 80vh;
    max-width: 80vw;
  }
`
const ModalPdfViewer = (props) => {
  const {
    closeOnOutsideClick,
    isOpen,
    handleModalClose,
    base64,
    filename = 'downloadPdf.pdf',
    errorMessage = '',
    loading = true,
    loadingText = '',
    showButtonClose = false,
    showDownloadButton = false
  } = props

  const [finish, setFinish] = useState(false)

  const createPdf = async () => {
    // Create a new PDFDocument
    const pdfDoc = await PDFDocument.load(base64)
    pdfDoc.setTitle(filename)

    // Serialize the PDFDocument to bytes (a Uint8Array)
    const pdfBytesBlob = await pdfDoc.save()
    const pdfBase64 = await pdfDoc.saveAsBase64({ dataUri: true })

    setFinish({ render: pdfBase64, blob: pdfBytesBlob })
  }

  const saveData = (byte, fileName) => {
    // eslint-disable-next-line no-undef
    const blob = new Blob([byte], { type: 'application/pdf' })
    const link = document.createElement('a')
    link.href = window.URL.createObjectURL(blob)
    link.download = fileName
    link.click()
  }

  useEffect(() => {
    if (!finish && isOpen && !loading) {
      createPdf()
    }
  }, [finish, isOpen, loading])

  return (
    <StyledModal
      closeOnOutsideClick={closeOnOutsideClick}
      isOpen={isOpen}
      handleModalClose={handleModalClose}
      showButtonClose={showButtonClose}
    >
      {errorMessage && (
        <Row justify='center' align='center'>
          <Col xs='content'>
            <SimpleNote text={errorMessage} noteStatus='error' />
          </Col>
        </Row>
      )}
      {loading && !errorMessage && (
        <Row style={{ height: 'calc(100% - 90px)', width: '100%' }}>
          <Col>
            <LoadingSpinner
              theme={{
                components: {
                  spinner: {
                    /**
                     * Has to be a { css } oject from styled-components
                     */
                    fontSize: portalTheme.font.size.bodySmall,
                    fontWeight: portalTheme.font.weight.regular,
                    size: '40px'
                  }
                }
              }}
              style={{
                position: 'unset',
                backgroundColor: 'white'
              }}
            />
          </Col>
        </Row>
      )}
      {!loading && !errorMessage && (
        <Row
          style={
            showDownloadButton
              ? { height: 'calc(100% - 90px)', width: '100%' }
              : { height: 'calc(100% - 30px)', width: '100%' }
          }
        >
          <Col>
            {finish.render && (
              <iframe
                download={filename}
                src={finish.render}
                width='100%'
                height='100%'
                display='block'
                type='application/pdf'
                name={Date.now()}
              />
            )}
          </Col>
        </Row>
      )}
      {showDownloadButton && (
        <Row style={{ marginTop: '10px' }} justify='center' align='center'>
          <Col xs='content'>
            <ButtonSecondary
              disabled={loading}
              onClick={() => {
                saveData(finish.blob, filename)
              }}
            >
              {loading ? (
                loadingText
              ) : (
                <Row>
                  <Col>{filename}</Col>
                  <Col>
                    <Icon icon='download' size='16px' aria-hidden='true' />
                  </Col>
                </Row>
              )}
            </ButtonSecondary>
          </Col>
        </Row>
      )}
    </StyledModal>
  )
}

ModalPdfViewer.defaultProps = {
  closeOnOutsideClick: true,
  isOpen: false,
  url: ''
}

ModalPdfViewer.propTypes = {
  closeOnOutsideClick: PropTypes.bool,
  url: PropTypes.string,
  isOpen: PropTypes.bool,
  handleModalClose: PropTypes.func.isRequired,
  base64: PropTypes.any.isRequired
}

export default ModalPdfViewer

import React from 'react'
import PropTypes from 'prop-types'

import { ButtonFlag } from '../Button'

import { LanguageListStyled } from './LanguageList.styles'

const LanguageList = (props) => {
  const { availableLanguages, currentLanguage, handleModalSelection, theme } =
    props

  const buttonFlagTheme = {
    components: {
      buttonFlag: {
        ...theme?.components?.langSwitcherModal?.buttonFlag
      }
    }
  }

  return (
    <LanguageListStyled>
      {availableLanguages.map((language, idx) => {
        const isActive = currentLanguage === language

        return (
          <ButtonFlag
            key={language + idx}
            language={language}
            isActive={isActive}
            mode='modal'
            handleClick={() => handleModalSelection(language)}
            theme={buttonFlagTheme}
          />
        )
      })}
    </LanguageListStyled>
  )
}

LanguageList.propTypes = {
  availableLanguages: PropTypes.arrayOf(PropTypes.string).isRequired,
  currentLanguage: PropTypes.string.isRequired,
  handleModalSelection: PropTypes.func,
  theme: PropTypes.shape({
    components: PropTypes.shape({
      langSwitcherModal: PropTypes.shape({
        buttonFlag: PropTypes.shape({
          headerMode: PropTypes.shape({
            borderBottomColor: PropTypes.string
          }),
          modalMode: PropTypes.shape({
            backgroundColor: PropTypes.string,
            active: PropTypes.shape({
              borderColor: PropTypes.string
            })
          }),
          normalMode: PropTypes.shape({
            backgroundColor: PropTypes.string
          })
        })
      })
    })
  })
}

export default LanguageList

import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'

import LanguageList from './LanguageList'
import ButtonPrimary from '../Button/ButtonPrimary/ButtonPrimary'

const LangSwitcherModalContent = (props) => {
  const {
    handleModalClose,
    availableLanguages,
    currentLanguage,
    changeLanguage,
    theme
  } = props

  const [modalSelection, setModalSelection] = useState(currentLanguage)

  const handleModalSelection = (flag) => {
    setModalSelection(flag)
  }

  const handleLanguageChange = useCallback(
    (lang) => {
      changeLanguage(lang)
      handleModalClose()
    },
    [changeLanguage, handleModalClose]
  )

  return (
    <>
      <LanguageList
        availableLanguages={availableLanguages}
        currentLanguage={modalSelection}
        handleModalSelection={handleModalSelection}
        theme={theme}
      />
      <ButtonPrimary
        onClick={() => handleLanguageChange(modalSelection)}
        type='button'
      >
        Change
      </ButtonPrimary>
    </>
  )
}

LangSwitcherModalContent.propTypes = {
  handleModalClose: PropTypes.func.isRequired,
  availableLanguages: PropTypes.arrayOf(PropTypes.string).isRequired,
  currentLanguage: PropTypes.string.isRequired,
  changeLanguage: PropTypes.func.isRequired,
  theme: PropTypes.shape({
    components: PropTypes.shape({
      langSwitcherModal: PropTypes.shape({
        buttonFlag: PropTypes.shape({
          headerMode: PropTypes.shape({
            borderBottomColor: PropTypes.string
          }),
          modalMode: PropTypes.shape({
            backgroundColor: PropTypes.string,
            active: PropTypes.shape({
              borderColor: PropTypes.string
            })
          }),
          normalMode: PropTypes.shape({
            backgroundColor: PropTypes.string
          })
        })
      })
    })
  })
}

export default LangSwitcherModalContent

import styled from 'styled-components'
import { allBorderBox } from '../../styles/helperStyles'
import { portalTheme } from '../..'
import { ReactDatePickerStyles } from './ReactDatePicker.styles'
import Icon from '../Icon/Icon'

const StyledInputStyled = styled.label`
  display: block;
  width: 100%;
  padding-top: 0px;
  position: relative;

  .react-datepicker-wrapper {
    width: 100%;
  }

  .input-wrapper {
    z-index: 1;
  }

  .floating-text {
    color: ${(props) =>
      props?.theme?.components?.styledInput?.span?.color ||
      portalTheme.color.inputPlaceholderColor};
    ${(props) =>
      props.disabled
        ? `color: ${portalTheme.color.disabledColor} !important;`
        : null}
    font-family: ${(props) =>
      props?.theme?.components?.styledInput?.span?.fontFamily ||
      portalTheme.font.family.quicksand};
    font-size: ${(props) =>
      props?.theme?.components?.styledInput?.span?.fontSize || '14px'};
    font-weight: ${(props) =>
      props?.theme?.components?.styledInput?.span?.fontWeight ||
      portalTheme.font.weight.regular};

    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    box-sizing: border-box;
    font-size: 1rem;
    left: 0;
    padding: 12px 0 13px 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    transition: font-size 200ms, padding 200ms;
    z-index: 2;

    &.floating {
      font-size: ${(props) =>
        props?.theme?.components?.styledInput?.floating?.fontSize || '11px'};
      padding: 0;
    }
  }

  input {
    -webkit-appearance: none;
    border-radius: 0;
    z-index: 2;
  }

  input,
  textarea,
  select {
    font-family: ${(props) =>
      props?.theme?.components?.styledInput?.fontFamily ||
      portalTheme.font.family.opensans};
    font-size: ${(props) =>
      props?.theme?.components?.styledInput?.fontSize || '15px'};
    font-weight: ${(props) =>
      props?.theme?.components?.styledInput?.fontWeight ||
      portalTheme.font.weight.regular};

    width: 100%;
    border: none;
    box-sizing: border-box;
    padding: 12px 0 0 0;

    outline: 0px;
    box-shadow: none;
    border-bottom: 1px solid
      ${(props) =>
        props?.theme?.components?.styledInput?.borderColor ||
        portalTheme.color.inputBorderColor};
    box-sizing: border-box;

    &:focus {
      border-color: ${(props) =>
        props?.theme?.components?.styledInput?.focus?.borderColor ||
        portalTheme.color.inputBorderColorHover};
    }
  }

  .react-datepicker-popper {
    z-index: 4 !important;
  }

  select {
    background-color: transparent;
    border-radius: 0px;
    appearance: none;
    background-position: right 50%;
    background-repeat: no-repeat;
    background-image: url('data:image/svg+xml;utf8,<?xml version="1.0" encoding="utf-8"?><!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="12" version="1"><path d="M4 8L0 4h8z"/></svg>');
    padding-right: 1.5em;
  }

  .reactSelectFilter {
    &__control {
      border-left: none;
      border-right: none;
      border-top: none;
      font-family: ${(props) =>
        props?.theme?.components?.styledInput?.fontFamily ||
        portalTheme.font.family.opensans};
      font-size: ${(props) =>
        props?.theme?.components?.styledInput?.fontSize || '15px'};
      font-weight: ${(props) =>
        props?.theme?.components?.styledInput?.fontWeight ||
        portalTheme.font.weight.regular};

      width: 100%;
      border: none;
      box-sizing: border-box;
      padding: 12px 0 0 0;

      outline: 0px;
      box-shadow: none;
      border-bottom: 1px solid
        ${(props) =>
          props?.theme?.components?.styledInput?.borderColor || props?.focus
            ? portalTheme.color.inputBorderColorHover
            : portalTheme.color.inputBorderColor};
      box-sizing: border-box;
      align-items: end;

      &:focus {
        border-color: ${(props) =>
          props?.theme?.components?.styledInput?.focus?.borderColor ||
          portalTheme.color.inputBorderColorHover};
      }

      &:hover {
        border-color: ${(props) =>
          props?.theme?.components?.styledInput?.focus?.borderColor ||
          portalTheme.color.inputBorderColorHover};
      }
    }

    &__indicator-separator {
      border-left: none;
    }

    &__control {
      padding: 0;
    }

    &__value-container {
      padding: 0;
    }

    &__menu {
      opacity: 1;
      margin: 0.125rem auto;
      z-index: 3;
    }

    &__option {
      background-color: white;

      color: black;

      &--is-focused {
        background-color: ${(props) =>
          portalTheme.color.linkColorSecondaryActive};
        color: black;
      }

      &__group {
        padding: 0;
      }

      &__menu-portal {
        opacity: 1;
        border: 1px solid darkblue;
      }
    }
  }

  textarea {
    font-family: ${(props) =>
      props?.theme?.components?.styledInput?.fontFamily ||
      portalTheme.font.family.opensans};
    font-size: ${(props) =>
      props?.theme?.components?.styledInput?.fontSize || '15px'};
    font-weight: ${(props) =>
      props?.theme?.components?.styledInput?.fontWeight ||
      portalTheme.font.weight.regular};
    padding: 0px;
    height: 5em;
    margin-top: 13px;
    background-color: transparent;
    border: 1px solid
      ${(props) =>
        props?.theme?.components?.styledInput?.borderColor ||
        portalTheme.color.inputBorderColor};
  }

  &.invalid {
    input,
    select {
      border-color: ${(props) =>
        props?.theme?.components?.styledInput?.invalid?.borderColor ||
        portalTheme.color.errorColor} !important;
    }
  }

  .feedback {
    height: 15px;
    margin-top: 0px;
    font-family: ${(props) =>
      props?.theme?.components?.styledInput?.feedback?.fontFamily ||
      portalTheme.font.family.quicksand};
    font-size: ${(props) =>
      props?.theme?.components?.styledInput?.feedback?.fontSize || '10px'};
    font-weight: ${(props) =>
      props?.theme?.components?.styledInput?.feedback?.fontWeight ||
      portalTheme.font.weight.regular};

    &.invalid {
      color: ${(props) =>
        props?.theme?.components?.styledInput?.invalid?.color ||
        portalTheme.color.errorColor};
    }
  }

  ${(props) => props?.theme?.components?.styledInput?.additionalStyles}
  ${ReactDatePickerStyles}
  ${allBorderBox}
`
const StyledPasswordIcon = styled(Icon)`
  float: right;
  margin-left: -25px;
  margin-top: -30px;
  position: relative;
  z-index: 3;
`

export { StyledInputStyled, StyledPasswordIcon }

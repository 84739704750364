import React from 'react'
import PropTypes from 'prop-types'

import { RouteLinkStyled } from './RouteLink.styles'

const RouteLink = (props) => {
  const { navLinkElement, theme } = props

  return <RouteLinkStyled theme={theme}>{navLinkElement}</RouteLinkStyled>
}

export default RouteLink

RouteLink.propTypes = {
  /** Has to be a NavLink element from react-router-dom **/
  navLinkElement: PropTypes.element.isRequired,
  theme: PropTypes.shape({
    components: PropTypes.shape({
      routeLink: PropTypes.shape({
        /**
         * Has to be a { css } object from styled-components
         */
        fontFamily: PropTypes.string,
        fontSize: PropTypes.string,
        fontWeight: PropTypes.string,
        color: PropTypes.string,
        borderBottomColor: PropTypes.string,
        additionalStyles: PropTypes.array,
        paddingBottom: PropTypes.string
      })
    })
  })
}

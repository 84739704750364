import styled from 'styled-components'
import { portalTheme } from '../../styles/PortalTheme'
import { Col, Row, Container } from 'react-grid-system'

const ImprintInfoContainerStyled = styled(Container)`
  margin-bottom: ${(props) =>
    props?.theme?.components?.imprintInfo?.marginBottom || '2em'};
  padding-top: ${(props) =>
    props?.theme?.components?.imprintInfo?.paddingTop || '1em'};
  padding-bottom: ${(props) =>
    props?.theme?.components?.imprintInfo?.paddingBottom || '1em'};
  background-color: ${(props) =>
    props?.theme?.components?.imprintInfo?.backgroundColor ||
    portalTheme.color.basicColorWhite};
  font-weight: ${(props) =>
    props?.theme?.components?.imprintInfo?.fontWeight ||
    portalTheme.font.weight.regular};
  font-size: ${(props) =>
    props?.theme?.components?.imprintInfo?.fontSize ||
    portalTheme.font.size.headingTiny};
  font-family: ${(props) =>
    props?.theme?.components?.imprintInfo?.fontFamily ||
    portalTheme.font.family.opensans};
`

const TitleRowStyled = styled(Row)`
  font-weight: ${(props) =>
    props?.theme?.components?.imprintInfo?.titleRow?.fontWeight ||
    portalTheme.font.weight.bold};
  background-color: ${(props) =>
    props?.theme?.components?.imprintInfo?.titleRow?.backgroundColor ||
    portalTheme.color.basicColorWhite};
  border-bottom: ${(props) =>
      props?.theme?.components?.imprintInfo?.titleRow?.borderBottom ||
      portalTheme.color.basicColorAccentRed}
    solid 2px;
  padding-bottom: ${(props) =>
    props?.theme?.components?.imprintInfo?.titleRow?.paddingBottom || '0.5em'};
  padding-left: ${(props) =>
    props?.theme?.components?.imprintInfo?.titleRow?.paddingLeft || '0px'};
  padding-right: ${(props) =>
    props?.theme?.components?.imprintInfo?.titleRow?.paddingRight || '0px'};
  font-size: ${(props) =>
    props?.theme?.components?.imprintInfo?.titleRow?.fontSize ||
    portalTheme.font.size.headingTiny};
  font-family: ${(props) =>
    props?.theme?.components?.imprintInfo?.titleRow?.fontFamily ||
    portalTheme.font.family.quicksand};
  color: ${(props) =>
    props?.theme?.components?.imprintInfo?.titleRow?.color ||
    portalTheme.color.basicColorBlack};
`

const ImprintBodyContainerStyled = styled(Container)`
  font-size: ${(props) =>
    props?.theme?.components?.imprintInfo?.imprintBodyContainer?.fontSize ||
    portalTheme.font.size.bodySmall};
  font-weight: ${(props) =>
    props?.theme?.components?.imprintInfo?.imprintBodyContainer?.fontWeight ||
    portalTheme.font.weight.regular};
  padding-top: ${(props) =>
    props?.theme?.components?.imprintInfo?.imprintBodyContainer?.paddingTop ||
    '1em'};
  padding-left: ${(props) =>
    props?.theme?.components?.imprintInfo?.imprintBodyContainer?.paddingLeft ||
    '2em'} !important;
`

const ImprintSectionTextColStyled = styled(Col)`
  white-space: ${(props) =>
    props?.theme?.components?.imprintInfo?.imprintSectionTextSpan?.whiteSpace ||
    'pre-line'} !important;
`

const ImprintFooterContainerStyled = styled(Container)`
  margin-top: ${(props) =>
    props?.theme?.components?.imprintInfo?.imprintFooterContainer?.marginTop ||
    '1em'} !important;
  font-size: ${(props) =>
    props?.theme?.components?.imprintInfo?.imprintFooterContainer?.fontSize ||
    '0.5em'};
  color: ${(props) =>
    props?.theme?.components?.imprintInfo?.imprintFooterContainer?.fontSize ||
    portalTheme.color.basicColorDarkGrey1};
`

export {
  ImprintInfoContainerStyled,
  TitleRowStyled,
  ImprintBodyContainerStyled,
  ImprintSectionTextColStyled,
  ImprintFooterContainerStyled
}

import styled, { css } from 'styled-components'

import { simpleLinkCSS } from '../SimpleLink/SimpleLink.styles'

import { portalTheme } from '../../styles/PortalTheme'
import { linkDecorationCSS } from '../../styles/helperStyles'

const navigationMenuCSS = css`
  ${linkDecorationCSS}

  font-family: ${(props) =>
    props?.theme?.components?.routeLink?.fontFamily ||
    portalTheme.font.family.quicksand};
  font-size: ${(props) =>
    props?.theme?.components?.routeLink?.fontSize ||
    portalTheme.font.size.navigationMenu};
  font-weight: ${(props) =>
    props?.theme?.components?.routeLink?.fontWeight ||
    portalTheme.font.weight.light};
  color: ${(props) =>
    props?.theme?.components?.routeLink?.color ||
    portalTheme.color.navigationMenu};
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: ${(props) =>
    props?.theme?.components?.routeLink?.paddingBottom || '1em'};
  display: inline-block;
  border-bottom: 5px solid rgba(0, 0, 0, 0);

  &:hover,
  &:focus {
    border-bottom: 5px solid
      ${(props) =>
        props?.theme?.components?.routeLink?.borderBottomColor ||
        portalTheme.color.basicColorAccentRed};
  }

  &:active {
    border-bottom: 5px solid
      ${(props) =>
        props?.theme?.components?.routeLink?.borderBottomColor ||
        portalTheme.color.basicColorAccentRed};
    font-weight: ${(props) =>
      props?.theme?.components?.routeLink?.fontWeight ||
      portalTheme.font.weight.bold};
  }
`

const navigationMenuActiveCSS = css`
  text-decoration: none;
  cursor: pointer;
  border-bottom: 5px solid
    ${(props) =>
      props?.theme?.components?.routeLink?.borderBottomColor ||
      portalTheme.color.basicColorAccentRed};
  font-weight: ${(props) =>
    props?.theme?.components?.routeLink?.fontWeight ||
    portalTheme.font.weight.bold};

  &.disable-border {
    &:focus {
      border: none;
      text-decoration: none !important;
      outline: none !important;
    }

    &:active {
      border: none;
      text-decoration: none;
      outline: none;
    }
  }
`

const linkColorPrimaryCSS = css`
  color: ${(props) =>
    props?.theme?.components?.routeLink?.color ||
    portalTheme.color.linkColorPrimary};

  &:hover,
  &:focus {
    color: ${(props) =>
      props?.theme?.components?.routeLink?.focus?.color ||
      portalTheme.color.linkColorPrimaryHover};
  }

  &:active {
    color: ${(props) =>
      props?.theme?.components?.routeLink?.active?.color ||
      portalTheme.color.linkColorPrimaryActive};
  }
`

export const RouteLinkStyled = styled.span`
  a {
    &.route-link__simple-link {
      ${simpleLinkCSS};
    }

    &.navigation-menu {
      ${navigationMenuCSS}
    }

    &.navigation-menu-active {
      ${navigationMenuActiveCSS}
    }

    &.link-decoration {
      ${linkDecorationCSS}
    }

    &.link-color-primary {
      ${linkColorPrimaryCSS}
    }

    ${(props) => props?.theme?.components?.routeLink?.additionalStyles}
  }
`

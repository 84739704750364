import React from 'react'
import PropTypes from 'prop-types'

import { StyledButtonSecondary, StyledSpinner } from './ButtonSecondary.styles'
import { portalTheme } from '../../../styles/PortalTheme'

const ButtonSecondary = (props) => (
  <StyledButtonSecondary {...props}>
    {props?.isLoading ? <StyledSpinner /> : props.children}
  </StyledButtonSecondary>
)

export default ButtonSecondary

ButtonSecondary.propTypes = {
  theme: PropTypes.shape({
    components: PropTypes.shape({
      buttonSecondary: PropTypes.shape({
        fontSize: PropTypes.string,
        fontWeight: PropTypes.string,
        color: PropTypes.string,
        backgroundColor: PropTypes.string,
        borderColor: PropTypes.string,
        fontFamily: PropTypes.string,
        focus: PropTypes.shape({
          backgroundColor: PropTypes.string,
          borderColor: PropTypes.string
        }),
        active: PropTypes.shape({
          backgroundColor: PropTypes.string,
          borderColor: PropTypes.string,
          color: PropTypes.string
        }),
        disabled: PropTypes.shape({
          color: PropTypes.string,
          borderColor: PropTypes.string,
          backgroundColor: PropTypes.string
        })
      })
    })
  })
}

ButtonSecondary.defaultProps = {
  theme: {
    components: {
      buttonSecondary: {
        fontSize: '14px',
        fontWeight: portalTheme.font.weight.regular,
        color: portalTheme.color.basicColorBlack,
        backgroundColor: portalTheme.color.basicColorWhite,
        borderColor: portalTheme.color.basicColorBlack,
        fontFamily: portalTheme.font.family.opensans,
        focus: {
          backgroundColor: portalTheme.color.basicColorWhite,
          borderColor: portalTheme.color.secondaryButtonBorderHover
        },
        active: {
          backgroundColor: portalTheme.color.basicColorWhite,
          color: portalTheme.color.secondaryButtonBorderActive,
          borderColor: portalTheme.color.secondaryButtonBorderActive
        },

        disabled: {
          color: portalTheme.color.disabledColor,
          borderColor: portalTheme.color.disabledColor,
          backgroundColor: portalTheme.color.basicColorWhite
        }
      }
    }
  }
}

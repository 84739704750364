import React, { useCallback, useEffect, useRef, useState } from 'react'
import Dropzone from 'react-dropzone'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { portalTheme } from '../../styles/PortalTheme'
import SimpleLabel from '../SimpleLabel/SimpleLabel'
import MessageBox from '../MessageBox/MessageBox'
import Icon from '../Icon/Icon'

const FileInputStyled = styled.div`
  .file-input {
    button {
      border-style: none;
    }

    p {
      padding: 0;
      margin: 0;
    }

    .dropzone-wrapper {
      display: flex;
      flex-direction: row;

      .dropzone-container {
        flex: 1;
        height: 80px;

        .dropzone {
          height: 100%;
          background-color: ${(props) =>
            props?.theme?.components?.fileInput?.dropZone?.backgroundColor ||
            portalTheme.color.basicColorLightGrey2};
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }
      }

      .file-list {
        transition: all 0.4s ease;
        width: 0px;
        margin-left: 5px;
        min-height: 80px;

        .close-button {
          margin-left: 5px;
        }

        .file-item {
          display: flex;
          flex-direction: row;
          height: 1.5em;
          align-items: center;

          .file-name {
            padding-left: 5px;
            flex: 1;
          }

          .file-remove-button {
            height: 100%;
            padding: 1px;
            display: flex;
            align-items: center;
            background-color: #fff;
          }

          &:nth-child(odd) {
            background: $basic-color-light-grey-2;
          }

          &:nth-child(even) {
            background: #fff;
          }
        }

        &.has-files {
          transition: all 0.4s ease;
          width: 60%;
        }
      }
    }
  }
`

const FileInput = (props) => {
  const [filesObject, setFilesObject] = useState({
    files: props.files === undefined ? [] : props.files,
    fileSize:
      props.files !== undefined
        ? props.files.reduce((accumulator, currentValue) => {
            return accumulator + parseInt(currentValue.size) / 1000
          }, 0)
        : 0
  })
  const [showTooMuchFileSizeWarning, setShowTooMuchFileSizeWarning] = useState(
    () => {
      return props.initShowTooMuchFileSizeWarning || false
    }
  )
  const inputRef = useRef(null)

  useEffect(() => {
    const onUpload = props.onUpload
    onUpload(props.name, filesObject.files)
  }, [filesObject.files, props.name, props.onUpload])

  const removeFile = useCallback(
    (fileArrayPosition) => {
      const newFiles = [...filesObject.files]
      newFiles.splice(fileArrayPosition, 1)
      return newFiles
    },
    [filesObject.files]
  )

  const readFile = useCallback((callBack, file) => {
    // eslint-disable-next-line no-undef
    const reader = new FileReader()

    reader.onabort = () => console.log('file reading was aborted')
    reader.onerror = () => console.log('file reading has failed')
    reader.onload = () => {
      // Do whatever you want with the file contents
      callBack(reader.result)
    }

    reader.readAsDataURL(file)
  }, [])

  const getItemCssClass = useCallback(() => {
    return filesObject.files.length > 0 ? 'has-files' : ''
  }, [filesObject.files])

  const showWarning = useCallback(() => {
    return (
      <MessageBox
        closeMessageBox={() => {
          setShowTooMuchFileSizeWarning(false)
        }}
        statusColor='warning-mark'
        staticBox
        isVisible={showTooMuchFileSizeWarning}
      >
        {props.labels.maxSizeReached} (max {props.maxsizeKb}kb)
      </MessageBox>
    )
  }, [showTooMuchFileSizeWarning, props.labels.maxSizeReached, props.maxsizeKb])

  const generateFileList = useCallback(() => {
    return filesObject.files.map((file, index) => {
      return (
        <div className='file-item' key={'file_' + index + file.name}>
          <span className='file-name'>{file.name}</span>
          <span>
            {parseInt(parseInt(filesObject.files[index].size) / 1000)}kb
          </span>
          <button
            className='file-remove-button'
            onClick={() => {
              const newFileSize =
                filesObject.fileSize -
                parseInt(filesObject.files[index].size) / 1000
              const newFiles = removeFile(index)
              setFilesObject({
                fileSize: newFileSize,
                files: newFiles
              })
            }}
          >
            <Icon icon='close' size='1em' aria-hidden='true' />
          </button>
        </div>
      )
    })
  }, [filesObject, removeFile])

  return (
    <FileInputStyled theme={props.theme}>
      <div className='file-input' ref={inputRef}>
        <SimpleLabel>{props.label}</SimpleLabel>
        <div className='dropzone-wrapper'>
          <div className='dropzone-container'>
            <Dropzone
              accept={props.supportedFileTypes}
              onDrop={(acceptedFiles) => {
                let tmpFilesObject = {
                  files: filesObject.files,
                  fileSize: filesObject.fileSize
                }
                let tmpfileSize = filesObject.fileSize
                acceptedFiles.forEach((file) => {
                  readFile((base64) => {
                    const size = Math.ceil(parseInt(file.size) / 1000)
                    file.base64 = base64
                    if (
                      !tmpFilesObject.files.find(
                        (fileSearch) => fileSearch.base64 === base64
                      )
                    ) {
                      tmpfileSize = tmpfileSize + size
                      if (tmpfileSize <= props.maxsizeKb) {
                        tmpFilesObject = {
                          files: [...tmpFilesObject.files, file],
                          fileSize: tmpfileSize
                        }
                        setFilesObject(tmpFilesObject)
                        if (showTooMuchFileSizeWarning) {
                          setShowTooMuchFileSizeWarning(false)
                        }
                      } else {
                        if (!showTooMuchFileSizeWarning) {
                          setShowTooMuchFileSizeWarning(true)
                        }
                      }
                    }
                  }, file)
                })
              }}
            >
              {({ getRootProps, getInputProps }) => (
                <div className='dropzone' {...getRootProps()}>
                  <input {...getInputProps()} />
                  <p>{props.labels.title}</p>

                  <Icon icon='drag-and-drop' size='1em' aria-hidden='true' />
                </div>
              )}
            </Dropzone>
          </div>
          <div className={'file-list ' + getItemCssClass()}>
            {generateFileList()}
          </div>
        </div>
      </div>
      <div className='justify-content-center my-2'>{showWarning()}</div>
    </FileInputStyled>
  )
}

FileInput.propTypes = {
  name: PropTypes.string.isRequired,
  maxsizeKb: PropTypes.number.isRequired,
  onUpload: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  labels: PropTypes.shape({
    title: PropTypes.string.isRequired,
    maxSizeReached: PropTypes.string.isRequired
  }),
  supportedFileTypes: PropTypes.string,
  /**
   * Can be used for stories
   */
  initShowTooMuchFileSizeWarning: PropTypes.bool,
  files: PropTypes.arrayOf(PropTypes.object),
  theme: PropTypes.shape({
    components: PropTypes.shape({
      fileInput: PropTypes.shape({
        dropzone: PropTypes.shape({
          backgroundColor: PropTypes.string
        })
      })
    })
  })
}
export default FileInput

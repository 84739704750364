import React from 'react'
import PropTypes from 'prop-types'

import iconSet from '../../styles/selection'

import { StyledIcon } from './Icon.styles'

const Icon = (props) => {
  return <StyledIcon iconSet={iconSet} {...props} />
}

Icon.propTypes = {
  icon: PropTypes.string.isRequired,
  size: PropTypes.any,
  color: PropTypes.string,
  style: PropTypes.object,
  disableFill: PropTypes.bool,
  removeInlineStyle: PropTypes.bool
}

export default Icon

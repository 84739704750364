import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'react-grid-system'

import RouteLink from '../RouteLink/RouteLink'

const NavigationBar = (props) => {
  const { navLinkElements } = props

  return navLinkElements ? (
    <Row>
      {navLinkElements.map((navLinkElement, index) => (
        <Col xs='content' key={'pageswitcher' + index}>
          <RouteLink navLinkElement={navLinkElement} />
        </Col>
      ))}
    </Row>
  ) : null
}

export default NavigationBar

NavigationBar.propTypes = {
  navLinkElements: PropTypes.arrayOf(PropTypes.element.isRequired).isRequired
}

import React from 'react'
import PropTypes from 'prop-types'

import {
  ToggleSwitchStyled,
  CheckboxStyled,
  SliderStyled
} from './ToggleSwitch.styles'

const ToggleSwitch = (props) => {
  const { isChecked, handleToggleChange } = props

  return (
    <ToggleSwitchStyled>
      <CheckboxStyled
        type='checkbox'
        checked={isChecked}
        onChange={handleToggleChange}
      />
      <SliderStyled />
    </ToggleSwitchStyled>
  )
}

export default ToggleSwitch

ToggleSwitch.propTypes = {
  isChecked: PropTypes.bool.isRequired,
  handleToggleChange: PropTypes.func.isRequired
}

import React, { useCallback } from 'react'
import { Col, Row, Container } from 'react-grid-system'
import PropTypes from 'prop-types'
import {
  ParagraphInfoContainerStyled,
  TitleRowStyled,
  ParagraphBodyContainerStyled,
  ParagraphRowStyled,
  ParagraphNumberColStyled,
  ParagraphNameColStyled,
  ParagraphSectionRowStyled,
  ParagraphSectionNumberColStyled,
  ParagraphSectionNameColStyled,
  ParagraphSectionTextColStyled,
  ParagraphFooterContainerStyled
} from './ParagraphInfo.styles'

const ParagraphInfo = (props) => {
  const generateParagraphsContent = useCallback(() => {
    const filteredContent = Object.keys(props.content).filter(
      (item) => item !== null
    )

    return (
      filteredContent?.map((key, indexKey) => {
        const structuredDocument = props.content[key]

        const paragraphsArray =
          structuredDocument?.paragraphs?.filter((item) => item !== null) || []

        const title = structuredDocument?.title || 'NO TITLE DEFINED'
        const coId = structuredDocument?.coId || 'NO CO_ID DEFINED'
        const coDate = structuredDocument?.date || 'NO CO DATE DEFINED'
        return (
          <ParagraphInfoContainerStyled
            key={'paragraph-wrapper' + indexKey}
            theme={props.theme}
            xs
            sm
            md
            lg
          >
            <TitleRowStyled nogutter theme={props.theme}>
              <Col xs='content' sm='content' md='content' lg='content'>
                {title}
              </Col>
            </TitleRowStyled>
            <Row nogutter>
              <Col xs={12} sm={12} md={12} lg={12}>
                {paragraphsArray.map((paragraph, paragraphIndex) => {
                  return (
                    <ParagraphBodyContainerStyled
                      theme={props.theme}
                      key={'paragraphIndex' + paragraphIndex}
                    >
                      <ParagraphRowStyled
                        className='ParagraphRowStyled'
                        theme={props.theme}
                        nogutter
                      >
                        <ParagraphNumberColStyled
                          theme={props.theme}
                          xs='content'
                          sm='content'
                          md='content'
                          lg='content'
                        >
                          {paragraph?.number}
                        </ParagraphNumberColStyled>
                        <ParagraphNameColStyled
                          theme={props.theme}
                          xs='content'
                          sm='content'
                          md='content'
                          lg='content'
                        >
                          {paragraph?.name}
                        </ParagraphNameColStyled>
                      </ParagraphRowStyled>
                      {paragraph?.sections
                        ?.filter((item) => item !== null)
                        ?.map((section, sectionIndex) => {
                          return (
                            <ParagraphSectionRowStyled
                              theme={props.theme}
                              key={'sectionIndex' + sectionIndex}
                              nogutter
                            >
                              <ParagraphSectionNumberColStyled
                                theme={props.theme}
                                xs='content'
                                sm='content'
                                md='content'
                                lg='content'
                              >
                                {section?.number}.
                              </ParagraphSectionNumberColStyled>
                              <ParagraphSectionNameColStyled
                                theme={props.theme}
                                xs='content'
                                sm='content'
                                md='content'
                                lg='content'
                              ></ParagraphSectionNameColStyled>
                              <ParagraphSectionTextColStyled
                                theme={props.theme}
                                dangerouslySetInnerHTML={{
                                  __html: section?.text
                                }}
                              />
                            </ParagraphSectionRowStyled>
                          )
                        })}
                    </ParagraphBodyContainerStyled>
                  )
                })}
                <ParagraphFooterContainerStyled theme={props.theme}>
                  <Row justify='end'>
                    <Col xs='content' sm='content' md='content' lg='content'>
                      co_{coId}
                    </Col>
                  </Row>
                  <Row justify='end'>
                    <Col xs='content' sm='content' md='content' lg='content'>
                      {coDate}
                    </Col>
                  </Row>
                </ParagraphFooterContainerStyled>
              </Col>
            </Row>
          </ParagraphInfoContainerStyled>
        )
      }) || 'NO CONTENT FOUND'
    )
  }, [props])

  return (
    <Container fluid style={{ paddingLeft: 0, paddingRight: 0 }}>
      <Row nogutter>
        <Col xs={12} sm={12} md={12} lg={12}>
          {generateParagraphsContent()}
        </Col>
      </Row>
    </Container>
  )
}

ParagraphInfo.propTypes = {
  theme: PropTypes.shape({
    components: PropTypes.shape({
      paragraphInfo: PropTypes.shape({
        /**
         * Has to be a { css } oject from styled-components
         */
        additionalStyles: PropTypes.array,
        marginBottom: PropTypes.array,
        paddingTop: PropTypes.string,
        paddingBottom: PropTypes.string,
        backgroundColor: PropTypes.string,
        fontWeight: PropTypes.string,
        fontSize: PropTypes.string,
        fontFamily: PropTypes.string,
        paragraphBodyContainer: PropTypes.shape({
          fontSize: PropTypes.string,
          fontWeight: PropTypes.string,
          paddingTop: PropTypes.string,
          paddingLeft: PropTypes.string
        }),
        paragraphRow: PropTypes.shape({
          fontSize: PropTypes.string
        }),
        paragraphNameCol: PropTypes.shape({
          paddingRight: PropTypes.string
        }),
        paragraphNumberCol: PropTypes.shape({
          paddingRight: PropTypes.string,
          fontWeight: PropTypes.string
        }),
        paragraphSectionRow: PropTypes.shape({
          paddingTop: PropTypes.string,
          paddingLeft: PropTypes.string
        }),
        paragraphSectionNumberCol: PropTypes.shape({
          paddingTop: PropTypes.string
        }),
        paragraphSectionNameCol: PropTypes.shape({
          fontWight: PropTypes.string
        }),
        paragraphSectionTextCol: PropTypes.shape({
          whiteSpace: PropTypes.string
        }),
        paragraphFooterContainer: PropTypes.shape({
          marginTop: PropTypes.string,
          fontSize: PropTypes.string,
          color: PropTypes.string
        }),
        titleRow: PropTypes.shape({
          fontWeight: PropTypes.string,
          backgroundColor: PropTypes.string,
          borderBottom: PropTypes.string,
          paddingBottom: PropTypes.string,
          paddingLeft: PropTypes.string,
          paddingRight: PropTypes.string,
          fontSize: PropTypes.string,
          fontFamily: PropTypes.string,
          color: PropTypes.string
        })
      })
    })
  })
}

export default ParagraphInfo

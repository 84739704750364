import styled, { css } from 'styled-components'
import { Col, Row } from 'react-grid-system'

import { allBorderBox, containerFluidStyles } from '../../styles/helperStyles'

const SimplePullDownStyled = styled.div`
  user-select: none;
  font-family: ${(props) => props.theme.components.simplePullDown.fontFamily};
  font-size: ${(props) => props?.theme.components.simplePullDown.fontSize};
  font-weight: ${(props) => props?.theme.components.simplePullDown.fontWeight};
  color: ${(props) => props?.theme.components.simplePullDown.color};

  ${allBorderBox}
  ${containerFluidStyles}
  ${(props) => props?.theme?.components?.simplePullDown?.additionalStyles}
`

const IconCol = styled(Row)`
  text-align: right;
  display: flex;
  align-items: center;
`

const pulldownOpenCSS = css`
  height: auto;
  max-height: 100%;
`

const PulldownValues = styled.div`
  display: block !important;
  flex-direction: column;
  font-family: ${(props) =>
    props.theme.components.simplePullDown.simplePullDownValuesRow.fontFamily};
  font-size: ${(props) =>
    props.theme.components.simplePullDown.simplePullDownValuesRow.fontSize};
  font-weight: ${(props) =>
    props.theme.components.simplePullDown.simplePullDownValuesRow.fontWeight};
  transition: max-height 0.2s ease;
  max-height: 0;
  overflow: hidden;

  ${({ isOpen }) => isOpen && pulldownOpenCSS};
`

const PulldownValuesFixedCol = styled(Col)`
  flex: 1 !important;
  /* min-height: 1 and flex direction column bug :/ */
`

export { SimplePullDownStyled, IconCol, PulldownValues, PulldownValuesFixedCol }

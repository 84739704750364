export const portalTheme = {
  grid: {
    breakpoints: [576, 768, 1200, 1201], // The breakpoints (minimum width) of devices in screen class sm, md, lg, and xl.
    containerWidths: [540, 720, 1300, 1301], // The container widths in pixels of devices in screen class sm, md, lg, and xl
    maxScreenClass: 'xl',
    defaultScreenClass: 'xl'
  },
  color: {
    basicColorBlack: '#000',
    basicColorWhite: '#FFF',
    basicColorDarkGrey1: '#777676',
    basicColorDarkGrey2: '#626262',
    basicColorDarkGrey3: '#4A4A4A',
    basicColorLightGrey1: '#F7FAF5',
    basicColorLightGrey2: '#F2F2F2',
    basicColorAccentRed: '#E8221B',
    disabledColor: '#BFBFBF',
    successColor: '#7ED321',
    warningColor: '#F5A623',
    errorColor: '#D0021B',
    linkColorPrimary: '#4AB4E2',
    linkColorPrimaryHover: '#3F96BC',
    linkColorPrimaryActive: '#3580A1',
    linkColorSecondary: '#C5CFDA',
    linkColorSecondaryHover: '#C5CFDA',
    linkColorSecondaryActive: '#8AA8C8',
    inputBorderColor: '#797979',
    inputBorderColorHover: '#000000',
    inputPlaceholderColor: '#7a8890',
    navigationMenu: '#000',
    langSwitcherDarkGrey: '#8ba8cb',
    langSwitcherGrey: '#c5cfda',
    secondaryButtonBorderHover: '#9D9D9D',
    secondaryButtonBorderActive: '#A4A4A4'
  },
  font: {
    family: {
      quicksand: '"Quicksand", sans-serif',
      opensans: '"Open Sans", sans-serif'
    },
    weight: {
      light: '500',
      regular: '500',
      bold: '600'
    },
    size: {
      headingRegular: '24px',
      headingSmall: '20px',
      headingTiny: '16px',
      headingTable: '12px',
      navigationLink: '16px',
      navigationLinkSmall: '14px',
      navigationMenu: '16px',
      inputPlaceholder: '14px',
      inputPlaceholderSmall: '11px',
      bodyRegular: '16px',
      bodySmall: '14px',
      inputText: '15px',
      inputLabel: '14px',
      inputError: '10px',
      labelRegular: '24px',
      labelSmall: '20px',
      labelTiny: '16px',
      messageBox: '14px',
      cookieBanner: '14px'
    }
  }
}

import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import ButtonIcon from '../Button/ButtonIcon/ButtonIcon'

import { portalTheme } from '../../styles/PortalTheme'
import { Col, Row } from 'react-grid-system'

const SearchBoxContainer = styled(Row)`
  position: relative;
  max-width: 350px;
  ${(props) => !props?.disabledborder && 'border: 2px solid black'};
  border-radius: 50px;
`

const SearchBoxLabel = styled.label`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 46px;
  width: 100%;
`

const SearchBoxStyled = styled.input`
  flex: 1;
  border: none;
  border-radius: 50px;

  font-family: ${portalTheme.font.family.quicksand};
  font-size: 1em;
  color: ${portalTheme.color.basicColorBlack};

  min-width: 0;

  transition: all 0.5s;

  &::placeholder {
    color: #767676;
    text-align: center;
  }

  ::-moz-placeholder {
    opacity: 1;
  }

  &:focus {
    outline: none;
    color: ${portalTheme.color.linkColorPrimary};

    &::placeholder {
      color: ${portalTheme.color.linkColorPrimary};
    }
  }

  &::-webkit-search-cancel-button {
    display: none;
  }
`

const SearchButton = styled(ButtonIcon).attrs((props) => ({
  icon: 'search',
  size: '28px',
  handleClick: props.handleClick
}))`
  margin: 0em;
`

const ClearButton = styled(ButtonIcon).attrs((props) => ({
  icon: 'close',
  size: '20px',
  isHidden: props.isHidden,
  handleClick: props.handleClick
}))`
  margin: 0em;
`

const Searchbox = (props) => {
  const {
    id = 'searchBox',
    placeholder,
    handleSubmit,
    handleChange,
    clearSearchAfterSubmit,
    clearButton,
    hideIcon = false,
    searchText = '',
    disabled = false,
    disabledborder = false,
    twoColStyle = false,
    disabledComponent = '',
    searchBoxContainerStyle = {},
    ...restOfProps
  } = props

  const [isClearButtonHidden, setIsClearButtonHidden] = useState(true)

  useEffect(() => {
    if (searchText.length > 0) {
      setIsClearButtonHidden(false)
    } else {
      setIsClearButtonHidden(true)
    }
  }, [searchText])

  const updateSearchText = (e) => {
    handleChange(e.target.value)
  }

  const clearSearchText = () => {
    handleChange('')
  }

  const handleClick = () => {
    if (searchText.length > 0) {
      handleSubmit()
      clearSearchAfterSubmit && clearSearchText()
    }
  }

  const handleOnEnterKeyPress = (e) => {
    if (e.key === 'Enter' && searchText.length > 0) {
      handleSubmit()
      clearSearchAfterSubmit && clearSearchText()
    }
  }

  return (
    <SearchBoxContainer
      justify={'center'}
      align='center'
      disabledborder={disabledborder ? 'true' : undefined}
      data-search-box-container=''
      style={searchBoxContainerStyle}
    >
      <Col>
        <Row justify={'center'} align='center' gutterWidth={5}>
          {!twoColStyle && (
            <Col
              xs={1}
              style={{
                display: 'flex',
                alignContent: 'center',
                justifyContent: 'center'
              }}
            >
              {!hideIcon && !disabled && (
                <SearchButton handleClick={handleClick} />
              )}
            </Col>
          )}

          <Col>
            <SearchBoxLabel htmlFor={id} {...restOfProps}>
              <SearchBoxStyled
                type='search'
                name={id}
                id={id}
                disabled={disabled}
                placeholder={placeholder}
                value={searchText}
                onChange={updateSearchText}
                onKeyDown={handleOnEnterKeyPress}
              />
            </SearchBoxLabel>
          </Col>
          <Col
            xs={1}
            style={{
              display: 'flex',
              alignContent: 'center',
              justifyContent: 'center'
            }}
          >
            {clearButton && !disabled && (
              <ClearButton
                isHidden={isClearButtonHidden}
                handleClick={clearSearchText}
              />
            )}
            {disabled && disabledComponent}
          </Col>
        </Row>
      </Col>
    </SearchBoxContainer>
  )
}

export default Searchbox

Searchbox.propTypes = {
  placeholder: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  clearSearchAfterSubmit: PropTypes.bool,
  clearButton: PropTypes.bool
}

Searchbox.defaultProps = {
  placeholder: 'Search',
  clearSearchAfterSubmit: true,
  clearButton: true
}

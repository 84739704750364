import styled from 'styled-components'

export const SimpleModalContent = styled.div`
  background-color: white;

  padding: 1.5em;
  max-width: 500px;

  display: flex;
  flex-flow: column wrap;
  flex: 1;

  user-select: none;

  @media screen and (max-width: 700px) {
    padding: 1em;
    max-width: 75%;
  }
`

export const SimpleModalContainer = styled.div`
  background-color: rgba(0, 0, 0, 0.6);

  display: none;
  position: fixed;
  z-index: 99999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;

  justify-content: center;
  align-items: center;

  ${({ isOpen }) =>
    isOpen &&
    `
      display: flex;
  `}

  user-select: none;
`

export const ButtonCloseContainer = styled.div`
  display: flex;
  align-self: flex-end;
`

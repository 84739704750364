import React from 'react'
import { StyledLoadingSpinner, StyledMessages } from './LoadingSpinner.styles'
import PropTypes from 'prop-types'
import Spinner from '../Spinner/Spinner'

const LoadingSpinner = (props) => {
  const { theme, message, boxSpinner, ...rest } = props
  return (
    <StyledLoadingSpinner theme={theme} boxSpinner={boxSpinner} {...rest}>
      <Spinner theme={theme} />
      <StyledMessages>{message}</StyledMessages>
    </StyledLoadingSpinner>
  )
}

LoadingSpinner.propTypes = {
  message: PropTypes.any,
  boxSpinner: PropTypes.any,
  theme: PropTypes.shape({
    components: PropTypes.shape({
      loadingSpinner: PropTypes.shape({
        /**
         * Has to be a { css } oject from styled-components
         */
        additionalStyles: PropTypes.array,
        fontFamily: PropTypes.string,
        fontSize: PropTypes.string,
        fontWeight: PropTypes.string,
        color: PropTypes.string
      })
    })
  })
}

export default LoadingSpinner

import styled from 'styled-components'
import { allBorderBox } from '../../styles/helperStyles'
import { portalTheme } from '../../styles/PortalTheme'

export const StyledLoadingSpinner = styled.div`
  z-index: 1000;
  position: ${(props) => (props?.boxSpinner ? 'absolute' : 'fixed')};
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  ${(props) => (props?.boxSpinner ? 'justify-content: center' : '')};

  background-color: rgb(119, 118, 118, 0.1);
  ${(props) => props?.theme?.components?.loadingSpinner?.additionalStyles}
  ${allBorderBox}
`

export const StyledMessages = styled.div`
  display: flex;
  justify-content: center;
  font-family: ${(props) =>
    props?.theme?.components?.loadingSpinner?.fontFamily ||
    portalTheme.font.family.quicksand};
  font-size: ${(props) =>
    props?.theme?.components?.loadingSpinner?.fontSize || '16px'};
  font-weight: ${(props) =>
    props?.theme?.components?.loadingSpinner?.fontWeight ||
    portalTheme.font.weight.bold};
  color: ${(props) =>
    props?.theme?.components?.loadingSpinner?.color ||
    portalTheme.color.basicColorBlack};
`

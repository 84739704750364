import React from 'react'
import PropTypes from 'prop-types'

import ToggleSwitch from './ToggleSwitch'
import SimpleLabel from '../SimpleLabel/SimpleLabel'

const ToggleSwitchWithLabel = (props) => {
  const { label, isChecked, handleToggleChange, theme } = props

  const simpleLabelTheme = {
    components: {
      simpleLabel: {
        ...theme?.components?.toggleSwitchWithLabel?.simpleLabel
      }
    }
  }

  return (
    <SimpleLabel theme={simpleLabelTheme}>
      {label}
      <ToggleSwitch
        isChecked={isChecked}
        handleToggleChange={handleToggleChange}
      />
    </SimpleLabel>
  )
}

export default ToggleSwitchWithLabel

ToggleSwitchWithLabel.propTypes = {
  label: PropTypes.string.isRequired,
  isChecked: PropTypes.bool.isRequired,
  handleToggleChange: PropTypes.func.isRequired,
  theme: PropTypes.shape({
    components: PropTypes.shape({
      toggleSwitchWithLabel: PropTypes.shape({
        simpleLabel: PropTypes.shape({
          fontFamily: PropTypes.string,
          fontWeight: PropTypes.string,
          fontSize: PropTypes.string,
          color: PropTypes.string
        })
      })
    })
  })
}

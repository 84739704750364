import React, { useCallback } from 'react'
import { Col, Row, Container } from 'react-grid-system'
import PropTypes from 'prop-types'
import {
  ImprintInfoContainerStyled,
  TitleRowStyled,
  ImprintBodyContainerStyled,
  ImprintSectionTextColStyled,
  ImprintFooterContainerStyled
} from './ImprintInfo.styles'

const ImprintInfo = (props) => {
  const generateImprintsContent = useCallback(() => {
    const filteredContent = Object.keys(props.content).filter(
      (item) => item !== null
    )

    return (
      filteredContent?.map((key, indexKey) => {
        const structuredDocument = props.content[key]
        const imprintsArray =
          structuredDocument?.paragraphs?.filter((item) => item !== null) || []

        const title = structuredDocument?.title || 'NO TITLE DEFINED'
        const coId = structuredDocument?.coId || 'NO CO_ID DEFINED'
        const coDate = structuredDocument?.date || 'NO CO DATE DEFINED'
        return (
          <ImprintInfoContainerStyled
            key={'imprint-wrapper' + indexKey}
            theme={props.theme}
            xs
            sm
            md
            lg
          >
            <TitleRowStyled nogutter theme={props.theme}>
              <Col xs='content' sm='content' md='content' lg='content'>
                {title}
              </Col>
            </TitleRowStyled>
            <Row nogutter>
              <Col xs={12} sm={12} md={12} lg={12}>
                {imprintsArray.map((imprint, imprintIndex) => {
                  return (
                    <ImprintBodyContainerStyled
                      theme={props.theme}
                      key={'imprintIndex' + imprintIndex}
                    >
                      {imprint?.sections
                        ?.filter((item) => item !== null)
                        ?.map((section, sectionIndex) => {
                          return (
                            <Row
                              theme={props.theme}
                              key={'sectionIndex' + sectionIndex}
                              nogutter
                            >
                              <ImprintSectionTextColStyled
                                theme={props.theme}
                                dangerouslySetInnerHTML={{
                                  __html: section?.text
                                }}
                              />
                            </Row>
                          )
                        })}
                    </ImprintBodyContainerStyled>
                  )
                })}
                <ImprintFooterContainerStyled theme={props.theme}>
                  <Row justify='end'>
                    <Col xs='content' sm='content' md='content' lg='content'>
                      co_{coId}
                    </Col>
                  </Row>
                  <Row justify='end'>
                    <Col xs='content' sm='content' md='content' lg='content'>
                      {coDate}
                    </Col>
                  </Row>
                </ImprintFooterContainerStyled>
              </Col>
            </Row>
          </ImprintInfoContainerStyled>
        )
      }) || 'NO CONTENT FOUND'
    )
  }, [props])

  return (
    <Container fluid style={{ paddingLeft: 0, paddingRight: 0 }}>
      <Row nogutter>
        <Col xs={12} sm={12} md={12} lg={12}>
          {generateImprintsContent()}
        </Col>
      </Row>
    </Container>
  )
}

ImprintInfo.propTypes = {
  theme: PropTypes.shape({
    components: PropTypes.shape({
      imprintInfo: PropTypes.shape({
        /**
         * Has to be a { css } oject from styled-components
         */
        additionalStyles: PropTypes.array,
        marginBottom: PropTypes.array,
        paddingTop: PropTypes.string,
        paddingBottom: PropTypes.string,
        backgroundColor: PropTypes.string,
        fontWeight: PropTypes.string,
        fontSize: PropTypes.string,
        fontFamily: PropTypes.string,
        imprintBodyContainer: PropTypes.shape({
          fontSize: PropTypes.string,
          fontWeight: PropTypes.string,
          paddingTop: PropTypes.string,
          paddingLeft: PropTypes.string
        }),
        imprintSectionTextCol: PropTypes.shape({
          whiteSpace: PropTypes.string
        }),
        imprintFooterContainer: PropTypes.shape({
          marginTop: PropTypes.string,
          fontSize: PropTypes.string,
          color: PropTypes.string
        }),
        titleRow: PropTypes.shape({
          fontWeight: PropTypes.string,
          backgroundColor: PropTypes.string,
          borderBottom: PropTypes.string,
          paddingBottom: PropTypes.string,
          paddingLeft: PropTypes.string,
          paddingRight: PropTypes.string,
          fontSize: PropTypes.string,
          fontFamily: PropTypes.string,
          color: PropTypes.string
        })
      })
    })
  })
}

export default ImprintInfo

import React from 'react'
import PropTypes from 'prop-types'

import { SimpleNoteStyled, TextStyled } from './SimpleNote.styles'
import { portalTheme } from '../../styles/PortalTheme'

const generateText = (text) => {
  if (typeof text === 'string') {
    const splitText = text.split('\n')

    if (splitText.length === 1) {
      return splitText[0]
    }

    return splitText.map((textPart, index) => {
      if (textPart === '') {
        return <br />
      }
      return <div key={'split_part_' + index}>{textPart}</div>
    })
  } else {
    return text
  }
}

const SimpleNote = (props) => {
  const { text, noteStatus, textStatus, theme, className, style } = props

  return (
    <SimpleNoteStyled
      noteStatus={noteStatus}
      theme={theme}
      className={className}
    >
      <TextStyled
        textStatus={textStatus}
        theme={theme}
        data-simple-note-text=''
        style={style}
      >
        {generateText(text)}
      </TextStyled>
    </SimpleNoteStyled>
  )
}

export default SimpleNote

SimpleNote.propTypes = {
  text: PropTypes.any.isRequired,
  noteStatus: PropTypes.oneOf(['warning', 'success', 'error', 'info']),
  textStatus: PropTypes.oneOf(['warning', 'info']),
  theme: PropTypes.shape({
    components: PropTypes.shape({
      simpleNote: PropTypes.shape({
        fontFamily: PropTypes.string,
        fontWeight: PropTypes.string,
        fontSize: PropTypes.string,
        textWarningColor: PropTypes.string,
        backgroundColor: PropTypes.string,
        successMark: PropTypes.shape({
          borderLeftColor: PropTypes.string
        }),
        warningMark: PropTypes.shape({
          borderLeftColor: PropTypes.string
        }),
        errorMark: PropTypes.shape({
          borderLeftColor: PropTypes.string
        })
      })
    })
  })
}

SimpleNote.defaultProps = {
  noteStatus: 'warning',
  textStatus: 'info',
  theme: {
    components: {
      simpleNote: {
        fontFamily: portalTheme.font.family.quicksand,
        fontWeight: portalTheme.font.weight.regular,
        fontSize: '16px',
        backgroundColor: portalTheme.color.basicColorWhite,
        textWarningColor: portalTheme.color.warningColor,
        successMark: {
          borderLeftColor: portalTheme.color.successColor
        },
        warningMark: {
          borderLeftColor: portalTheme.color.warningColor
        },
        errorMark: {
          borderLeftColor: portalTheme.color.errorColor
        }
      }
    }
  }
}

import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import Icon from '../Icon/Icon'
import {
  CustomCheckboxRadioStyled,
  StyledFlexDiv
} from './CustomerCheckboxRadio.styles'

const CustomCheckboxRadio = (props) => {
  const generateIcon = useCallback(() => {
    const icons = {
      checkbox: {
        active: props.overrideActiveIcon || 'checkbox-checked',
        deactivated: props.overrideDeactivatedIcon || 'checkbox-unchecked'
      },
      radio: {
        active: props.overrideActiveIcon || 'radio-button-checked',
        deactivated: props.overrideDeactivatedIcon || 'radio-button-unchecked'
      }
    }
    let setIcon
    if (props.type === 'checkbox') {
      setIcon = props.isChecked
        ? icons.checkbox.active
        : icons.checkbox.deactivated
    } else {
      setIcon = props.isChecked ? icons.radio.active : icons.radio.deactivated
    }
    return (
      <Icon
        className={'icon ' + (props.disabled && 'disabled')}
        icon={setIcon}
        size={
          props.theme?.components?.customCheckboxRadio?.icon?.fontSize || '25px'
        }
        aria-hidden='true'
      />
    )
  }, [
    props.isChecked,
    props.disabled,
    props.type,
    props.theme,
    props.overrideActiveIcon,
    props.overrideDeactivatedIcon
  ])
  const idParam = props.name + props.id

  return (
    <CustomCheckboxRadioStyled
      isChecked={props.isChecked}
      theme={props.theme}
      htmlFor={idParam}
    >
      <StyledFlexDiv>
        <div className='input-wrapper'>
          <input
            type={props.type}
            name={props.name}
            id={idParam}
            value={props.value}
            onChange={props.onChange}
            checked={props.isChecked}
            disabled={props.disabled}
          />
          {!props.hideIcon && generateIcon(props.type)}
        </div>
        <div className='filler-wrapper'>{props.labelElement}</div>
      </StyledFlexDiv>
    </CustomCheckboxRadioStyled>
  )
}

export default CustomCheckboxRadio

CustomCheckboxRadio.propTypes = {
  /**
   * Default is 'checkbox' anything else will be a radio style
   */
  type: PropTypes.string,
  value: PropTypes.string,
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  isChecked: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool,
  hideIcon: PropTypes.bool,
  labelElement: PropTypes.node,
  overrideActiveIcon: PropTypes.string,
  overrideDeactivatedIcon: PropTypes.string,
  theme: PropTypes.shape({
    components: PropTypes.shape({
      customCheckboxRadio: PropTypes.shape({
        /**
         * Has to be a { css } oject from styled-components
         */
        additionalStyles: PropTypes.array,
        /**
         * Has to be a { css } oject from styled-components
         */
        additionalStylesWhenChecked: PropTypes.array,
        fontFamily: PropTypes.string,
        icon: PropTypes.shape({
          fontSize: PropTypes.string,
          color: PropTypes.string
        })
      })
    })
  })
}

CustomCheckboxRadio.defaultProps = {
  type: 'checkbox'
}

import styled from 'styled-components'
import React from 'react'
import LetterContentStatic from './LetterContentStatic/LetterContentStatic'

const LetterLayoutStyled = styled.div`
  ${(props) => `
        border: 2px solid #99b3cf;
        border-radius: 5px;
        background: white;
        width: ${props.defaultA4Width};
        height: ${props.defaultA4Height};
        padding-left: ${props.paddingLeft};
        padding-top: ${props.paddingTop};
        padding-bottom: ${props.paddingBottom};
        padding-right: ${props.paddingRight};
        `}
`

const LetterMarginWrapperStyled = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`

const LetterLayout = (props) => {
  const {
    setActiveContainerKey = (keyTmp) => {},
    activeContainer,
    setActiveContainer,
    containers,
    externTranslateFunction = (value) => {
      return value
    },
    clickAble = true,
    previewWidth = false,
    previewShow = true,
    adminEdit = false,
    customRenderer = false,
    formErrors = [],
    paddings = { left: '25mm', top: '11mm', right: '11mm', bottom: '14mm' }
  } = props

  const defaultA4Props = {
    width: '21cm',
    height: '29.7cm'
  }

  const previewDivider = previewWidth
    ? (38 * parseFloat(defaultA4Props.width)) / props.previewWidth
    : false

  const cutNumber = (number, digitsAfterDot) => {
    const str = `${number}`

    return str.slice(0, str.indexOf('.') + digitsAfterDot + 1)
  }

  const generateValueUnitDivided = (value = '', key = '') => {
    const allowedChars = '%pxcm'
    let unit = Array.from(value)
      .filter((f) => allowedChars.includes(f))
      .join('')
    const hasNumber = /\d/.test(value)

    if (!unit) {
      unit = 'mm'
    }

    if (unit === '%') {
      return value
    }

    if (!hasNumber) {
      return value
    }

    const returnValue =
      previewDivider && hasNumber ? parseFloat(value) / previewDivider : value
    return key === 'fontSize'
      ? cutNumber(parseFloat(returnValue).toFixed(3), 1) + unit
      : parseFloat(returnValue).toFixed(3) + unit
  }

  const generateContainer = (tempContainers, keyTmp) => {
    const arrayContainer = [tempContainers].flat()
    return arrayContainer.map((object, index) => {
      return (
        <LetterContentStatic
          generateValueUnitDivided={generateValueUnitDivided}
          previewShow={previewShow}
          key={index + 'container' + object.id}
          formError={formErrors.find(
            (element) => element === keyTmp + '_' + object.id
          )}
          onClick={() => {
            if (
              clickAble &&
              ((previewShow && adminEdit && !object?.editable) ||
                (previewShow && !adminEdit && object?.editable))
            ) {
              setActiveContainer(object)
              setActiveContainerKey(keyTmp)
            }
          }}
          index={index}
          title={
            previewShow ? externTranslateFunction(object?.title?.text) : ''
          }
          description={object?.description}
          titleStyles={
            previewShow && object?.title?.previewStyle
              ? Object.keys(object?.title?.previewStyle).reduce((acc, key) => {
                  acc[key] = generateValueUnitDivided(
                    object?.title?.previewStyle[key],
                    key
                  )
                  return acc
                }, {})
              : {}
          }
          previewStyle={
            object?.previewStyle
              ? Object.keys(object?.previewStyle).reduce((acc, key) => {
                  acc[key] = generateValueUnitDivided(
                    object?.previewStyle[key],
                    key
                  )
                  return acc
                }, {})
              : {}
          }
          customRenderer={customRenderer}
          active={activeContainer?.id === object.id}
          content={object.content}
          contentType={object?.contentType}
          editable={object?.editable}
          adminEdit={adminEdit}
        />
      )
    })
  }

  return (
    <LetterLayoutStyled
      paddingLeft={generateValueUnitDivided(paddings.left)}
      paddingRight={generateValueUnitDivided(paddings.right)}
      paddingTop={generateValueUnitDivided(paddings.top)}
      paddingBottom={generateValueUnitDivided(paddings.bottom)}
      previewDivider={previewDivider}
      defaultA4Width={generateValueUnitDivided(defaultA4Props.width)}
      defaultA4Height={generateValueUnitDivided(defaultA4Props.height)}
    >
      <LetterMarginWrapperStyled>
        {generateContainer(containers.header, 'header')}
        {generateContainer(containers.sender, 'sender')}
        {generateContainer(containers.addressWindow, 'addressWindow')}
        {generateContainer(containers.content, 'content')}
        {generateContainer(containers.footer, 'footer')}
      </LetterMarginWrapperStyled>
    </LetterLayoutStyled>
  )
}
export default LetterLayout

import React from 'react'
import { Row, Col } from 'react-grid-system'
import PropTypes from 'prop-types'

import RouteLink from '../RouteLink/RouteLink'

const definePageSwitcherMode = (mode) => {
  if (mode === 'desktop') {
    return {
      xs: 'content',
      sm: 'content',
      md: 'content',
      lg: 'content'
      // addClassName: 'navigation-menu',
      // addActiveClassName: 'navigation-menu-active'
    }
  } else if (mode === 'mobile') {
    return {
      xs: 12,
      sm: 6,
      md: 6,
      lg: 6
      // addClassName: 'link-decoration link-color-primary',
      // addActiveClassName: ''
    }
  } else if (mode !== null && typeof mode === 'object') {
    return {
      xs: mode.xs,
      sm: mode.sm,
      md: mode.md,
      lg: mode.lg
      // addClassName: 'link-decoration link-color-primary',
      // addActiveClassName: ''
    }
  } else {
    return {
      xs: 'content',
      sm: 'content',
      md: 'content',
      lg: 'content'
      // addClassName: '',
      // addActiveClassName: ''
    }
  }
}

const PageSwitcher = (props) => {
  const { navLinkElements, mode, theme } = props

  const routeLinkTheme = {
    components: {
      routeLink: {
        ...theme?.components?.pageSwitcher?.routeLink
      }
    }
  }

  const { xs, sm, md, lg } = definePageSwitcherMode(mode)

  return navLinkElements ? (
    <Row>
      {navLinkElements.map((navLinkElement, index) => (
        <Col xs={xs} sm={sm} md={md} lg={lg} key={'pageswitcher' + index}>
          <RouteLink navLinkElement={navLinkElement} theme={routeLinkTheme} />
        </Col>
      ))}
    </Row>
  ) : null
}

PageSwitcher.propTypes = {
  navLinkElements: PropTypes.arrayOf(PropTypes.element.isRequired).isRequired,
  mode: PropTypes.string,
  theme: PropTypes.shape({
    components: PropTypes.shape({
      pageSwitcher: PropTypes.shape({
        routeLink: PropTypes.shape({
          /**
           * Has to be a { css } object from styled-components
           */
          fontFamily: PropTypes.string,
          fontSize: PropTypes.string,
          fontWeight: PropTypes.string,
          color: PropTypes.string,
          borderBottomColor: PropTypes.string
        })
      })
    })
  })
}

PageSwitcher.defaultProps = {
  mode: 'desktop'
}

export default PageSwitcher

import styled from 'styled-components'
import { allBorderBox } from '../../styles/helperStyles'

export const StyledSpinner = styled.div`
  height: ${(props) => props?.theme?.components?.spinner?.size || '60px'};
  width: ${(props) => props?.theme?.components?.spinner?.size || '60px'};
  margin: 0 auto;

  -webkit-animation: rotation 0.6s infinite linear;
  -moz-animation: rotation 0.6s infinite linear;
  -o-animation: rotation 0.6s infinite linear;
  animation: rotation 0.6s infinite linear;

  border-radius: 100%;
  border-width: ${(props) =>
    props?.theme?.components?.spinner?.border || '6px'};
  border-style: solid;
  border-color: rgba(232, 34, 27, 0.15);
  border-top-color: rgba(232, 34, 27, 0.8);

  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }

  ${allBorderBox}
  ${(props) => props?.theme?.components?.spinner?.additionalStyles}
`

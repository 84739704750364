import PropTypes from 'prop-types'
import React from 'react'

import SimpleModal from '../SimpleModal/SimpleModal'
import LangSwitcherModalContent from './LangSwitcherModalContent'

import { portalTheme } from '../../styles/PortalTheme'

const LangSwitcherModal = (props) => {
  const {
    isOpen,
    handleModalClose,
    availableLanguages,
    currentLanguage,
    changeLanguage,
    theme
  } = props

  return (
    <SimpleModal isOpen={isOpen} handleModalClose={handleModalClose}>
      <LangSwitcherModalContent
        handleModalClose={handleModalClose}
        availableLanguages={availableLanguages}
        currentLanguage={currentLanguage}
        changeLanguage={changeLanguage}
        theme={theme}
      />
    </SimpleModal>
  )
}

LangSwitcherModal.propTypes = {
  isOpen: PropTypes.bool,
  handleModalClose: PropTypes.func.isRequired,
  availableLanguages: PropTypes.arrayOf(PropTypes.string).isRequired,
  currentLanguage: PropTypes.string.isRequired,
  changeLanguage: PropTypes.func.isRequired,
  theme: PropTypes.shape({
    components: PropTypes.shape({
      langSwitcherModal: PropTypes.shape({
        buttonFlag: PropTypes.shape({
          headerMode: PropTypes.shape({
            borderBottomColor: PropTypes.string
          }),
          modalMode: PropTypes.shape({
            backgroundColor: PropTypes.string,
            active: PropTypes.shape({
              borderColor: PropTypes.string
            })
          }),
          normalMode: PropTypes.shape({
            backgroundColor: PropTypes.string
          })
        })
      })
    })
  })
}

LangSwitcherModal.defaultProps = {
  isOpen: false,
  theme: {
    components: {
      langSwitcherModal: {
        buttonFlag: {
          headerMode: {
            borderBottomColor: portalTheme.color.basicColorAccentRed
          },
          modalMode: {
            backgroundColor: portalTheme.color.langSwitcherGrey,
            active: {
              borderColor: portalTheme.color.langSwitcherDarkGrey
            }
          },
          normalMode: {
            backgroundColor: portalTheme.color.langSwitcherGrey
          }
        }
      }
    }
  }
}

export default LangSwitcherModal

import { css } from 'styled-components'

export const unstyledList = css`
  padding-left: 0;
  margin: 0;
  list-style: none;
`

export const allBorderBox = css`
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }
`
export const linkDecorationCSS = css`
  cursor: pointer;
  background-color: transparent;
  text-decoration: none;

  &:hover,
  &:focus {
    cursor: pointer;
    background-color: transparent;
    text-decoration: none;
  }

  &:active {
    cursor: pointer;
    background-color: transparent;
    text-decoration: none;
  }

  &:disabled {
    cursor: none;
  }
`
export const containerFluidStyles = css`
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
`

export const buttonRemoveBrowserStylesCss = css`
  user-select: none;
  outline: none !important;
  cursor: pointer;
  box-shadow: none;
`

export const buttonStylesDefaultCss = css`
  border-radius: 30px;
  height: 48px;
  padding-left: 4em;
  padding-right: 4em;
  margin-left: 10px;
  margin-right: 10px;
  ${buttonRemoveBrowserStylesCss}
`

import React from 'react'
import PropTypes from 'prop-types'

import SimpleLabel from '../../SimpleLabel/SimpleLabel'

import { StyledButtonSelector } from './ButtonSelector.styles'
import { portalTheme } from '../../../styles/PortalTheme'

const ButtonSelector = (props) => (
  <StyledButtonSelector
    className={`${props.isActive && 'active'}`}
    theme={props.theme}
    {...props}
  >
    <SimpleLabel>{props.children}</SimpleLabel>
  </StyledButtonSelector>
)

export default ButtonSelector

ButtonSelector.propTypes = {
  isActive: PropTypes.bool,
  theme: PropTypes.shape({
    components: PropTypes.shape({
      buttonSelector: PropTypes.shape({
        color: PropTypes.string,
        borderColor: PropTypes.string,
        active: PropTypes.shape({
          color: PropTypes.string,
          borderColor: PropTypes.string,
          backgroundColor: PropTypes.string
        })
      })
    })
  })
}

ButtonSelector.defaultProps = {
  isActive: false,
  theme: {
    components: {
      buttonSelector: {
        backgroundColor: portalTheme.color.basicColorWhite,
        color: portalTheme.color.basicColorBlack,
        borderColor: portalTheme.color.linkColorSecondary,
        active: {
          color: portalTheme.color.basicColorWhite,
          borderColor: portalTheme.color.linkColorSecondaryActive,
          backgroundColor: portalTheme.color.linkColorSecondaryActive
        }
      }
    }
  }
}

import React, { useEffect, useRef } from 'react'
import { portalTheme } from '../../styles/PortalTheme'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Icon from '../Icon/Icon'

const MessageNofiStyled = styled.div`
  position: fixed;
  left: 0%;
  bottom: 0%;
  font-family: ${(props) =>
    props?.theme?.components?.messageBox?.fontFamily ||
    portalTheme.font.family.quicksand};
  font-size: ${(props) =>
    props?.theme?.components?.messageBox?.fontSize || '14px'};
  font-weight: ${(props) =>
    props?.theme?.components?.messageBox?.fontWeight ||
    portalTheme.font.weight.regular};
  background-color: rgba(194, 237, 255, 0.4);
  transition: all 0.5s ease;
  opacity: 1;
  z-index: 10;
  width: 100%;
  border-radius: 7px;
  border: 1.5px solid ${(props) => props.statusColor};
  padding: 7px;
  font-size: 20px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-items: center;
  justify-content: center;
  align-content: center;
  gap: 5px;

  &.hide {
    opacity: 0;
    z-index: -1;
  }
`

const MessageNofi = (props) => {
  const { statusColor = 'default' } = props

  const generateStatusColor = () => {
    switch (statusColor) {
      case 'success':
        return portalTheme.color.successColor
      case 'warning':
        return portalTheme.color.warningColor
      case 'error':
        return portalTheme.color.errorColor
      default:
        return portalTheme.color.basicColorBlack
    }
  }

  const generateStatusIcon = () => {
    switch (statusColor) {
      case 'success':
        return 'bullet-point'
      case 'warning':
        return 'info'
      case 'error':
        return 'info'
      default:
        return 'info'
    }
  }
  const timer = useRef(false) // now you can pass timer to another component
  const closeMessageBox = props.closeMessageBox
  useEffect(() => {
    if (!props.staticBox) {
      if (props.isVisible) {
        clearTimeout(timer.current)
        timer.current = setTimeout(() => closeMessageBox(), 2000)
      } else {
        clearTimeout(timer.current)
      }
    }
  }, [props.isVisible, props.updated, props.closeMessageBox, props.staticBox])

  return (
    <MessageNofiStyled
      onClick={() => {
        closeMessageBox()
      }}
      style={props?.style}
      statusColor={generateStatusColor()}
      className={!props.isVisible && 'hide'}
    >
      <Icon
        color={generateStatusColor()}
        icon={generateStatusIcon()}
        size='24px'
        aria-hidden='true'
      />
      {props.children}
    </MessageNofiStyled>
  )
}

MessageNofi.propTypes = {
  children: PropTypes.node.isRequired,
  closeMessageBox: PropTypes.func,
  isVisible: PropTypes.bool.isRequired,
  updated: PropTypes.instanceOf(Date),
  /**
   * To show the status bar, use following parameters.<br>
   * success: success-mark<br>
   * warning: warning-mark<br>
   * error: error-mark<br>
   */
  statusColor: PropTypes.any
}

export default MessageNofi

import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import Cookies from './Cookies'
import CookieBannerContent from './CookieBannerContent'
import { portalTheme } from '../../styles/PortalTheme'

export const CONSENT_GIVEN = 'rcl_consent_given'
export const PREFERENCES_COOKIE = 'rcl_preferences_consent'
export const STATISTICS_COOKIE = 'rcl_statistics_consent'
export const MARKETING_COOKIE = 'rcl_marketing_consent'

export const hasCookie = (CookieType, bannerPrefix) => {
  const cookies = new Cookies()
  switch (CookieType) {
    case 'consent':
      return cookies.get(bannerPrefix + CONSENT_GIVEN)
    case 'preferences':
      return cookies.get(bannerPrefix + PREFERENCES_COOKIE)
    case 'statistics':
      return cookies.get(bannerPrefix + STATISTICS_COOKIE)
    case 'marketing':
      return cookies.get(bannerPrefix + MARKETING_COOKIE)
    default:
      return false
  }
}

const CookieBanner = (props) => {
  const [bannerPrefix] = useState(props.bannerPrefix)
  const cookiesInstance = new Cookies()
  const [showBanner, setShowBanner] = useState(
    !cookiesInstance.get(bannerPrefix + CONSENT_GIVEN)
  )
  const [preferencesCookies, setPreferencesCookies] = useState(
    !cookiesInstance.get(bannerPrefix + PREFERENCES_COOKIE)
  )
  const [statisticsCookies, setStatisticsCookies] = useState(
    !cookiesInstance.get(bannerPrefix + STATISTICS_COOKIE)
  )
  const [marketingCookies, setMarketingCookies] = useState(false)

  const onTogglePreferencesCookies = useCallback(() => {
    setPreferencesCookies(!preferencesCookies)
  }, [preferencesCookies])

  const onToggleStatisticsCookies = useCallback(() => {
    setStatisticsCookies(!statisticsCookies)
  }, [statisticsCookies])

  const onToggleMarketingCookies = useCallback(() => {
    setMarketingCookies(!marketingCookies)
  }, [marketingCookies])

  const confirm = useCallback(() => {
    cookiesInstance.set(bannerPrefix + CONSENT_GIVEN)

    if (preferencesCookies) {
      cookiesInstance.set(bannerPrefix + PREFERENCES_COOKIE)
    } else {
      cookiesInstance.remove(bannerPrefix + PREFERENCES_COOKIE)
    }

    if (statisticsCookies) {
      cookiesInstance.set(bannerPrefix + STATISTICS_COOKIE)
    } else {
      cookiesInstance.remove(bannerPrefix + STATISTICS_COOKIE)
    }

    if (marketingCookies) {
      cookiesInstance.set(bannerPrefix + MARKETING_COOKIE)
    } else {
      cookiesInstance.remove(bannerPrefix + MARKETING_COOKIE)
    }

    clickOnConfirm()
    setShowBanner(false)
  }, [preferencesCookies, statisticsCookies, marketingCookies, cookiesInstance])

  const decline = useCallback(() => {
    setShowBanner(false)
    cookiesInstance.remove(bannerPrefix + CONSENT_GIVEN)
    cookiesInstance.remove(bannerPrefix + PREFERENCES_COOKIE)
    cookiesInstance.remove(bannerPrefix + STATISTICS_COOKIE)
    cookiesInstance.remove(bannerPrefix + MARKETING_COOKIE)
    const onDeclinePreferences = props.onDeclinePreferences
    const onDeclineStatistics = props.onDeclineStatistics
    const onDeclineMarketing = props.onDeclineMarketing

    onDeclinePreferences()
    onDeclineStatistics()
    onDeclineMarketing()

    // this.forceUpdate()
  }, [
    props.onDeclinePreferences,
    props.onDeclineStatistics,
    props.onDeclineMarketing,
    cookiesInstance
  ])

  const consentsCallback = useCallback(() => {
    const hasPreferencesCookie = cookiesInstance.get(
      bannerPrefix + PREFERENCES_COOKIE
    )
    const hasStatisticsCookie = cookiesInstance.get(
      bannerPrefix + STATISTICS_COOKIE
    )
    const hasMarketingCookie = cookiesInstance.get(
      bannerPrefix + MARKETING_COOKIE
    )
    const onAccept = props.onAccept
    const onAcceptPreferences = props.onAcceptPreferences
    const onDeclinePreferences = props.onDeclinePreferences
    const onAcceptStatistics = props.onAcceptStatistics
    const onDeclineStatistics = props.onDeclineStatistics
    const onAcceptMarketing = props.onAcceptMarketing
    const onDeclineMarketing = props.onDeclineMarketing

    onAccept()

    if (hasPreferencesCookie) {
      onAcceptPreferences()
    } else {
      onDeclinePreferences()
    }

    if (hasStatisticsCookie) {
      onAcceptStatistics()
    } else {
      onDeclineStatistics()
    }

    if (hasMarketingCookie) {
      onAcceptMarketing()
    } else {
      onDeclineMarketing()
    }
  }, [
    cookiesInstance,
    props.onAccept,
    props.onAcceptPreferences,
    props.onAcceptStatistics,
    props.onAcceptMarketing,
    props.onDeclinePreferences,
    props.onDeclineStatistics,
    props.onDeclineMarketing
  ])

  window.openCookieBanner = useCallback(() => {
    setShowBanner(true)
  }, [setShowBanner])

  const {
    theme,
    className,
    message,
    policyLink,
    privacyPolicyLinkText,
    necessaryOptionText,
    preferencesOptionText,
    checkboxHeaderLabel,
    statisticsOptionText,
    marketingOptionText,
    showDeclineButton,
    acceptButtonText,
    declineButtonText,
    showPreferencesOption,
    showStatisticsOption,
    showMarketingOption,
    clickOnConfirm
  } = props

  if (cookiesInstance.get(CONSENT_GIVEN)) {
    consentsCallback()
  }

  const contentProps = {
    theme,
    className,
    message,
    policyLink,
    privacyPolicyLinkText,
    necessaryOptionText,
    checkboxHeaderLabel,
    preferencesOptionText,
    statisticsOptionText,
    marketingOptionText,
    showDeclineButton,
    acceptButtonText,
    declineButtonText,
    showPreferencesOption,
    showStatisticsOption,
    showMarketingOption,
    initCheckPref: preferencesCookies,
    initCheckStats: statisticsCookies,
    onTogglePreferencesCookies: onTogglePreferencesCookies,
    onToggleStatisticsCookies: onToggleStatisticsCookies,
    onToggleMarketingCookies: onToggleMarketingCookies,
    onDecline: decline,
    onConfirm: confirm,
    clickOnConfirm: clickOnConfirm
  }

  return showBanner && <CookieBannerContent {...contentProps} />
}
CookieBanner.defaultProps = {
  theme: {
    components: {
      cookieBanner: {
        fontSize: '14px',
        fontWeight: portalTheme.font.weight.bold,
        fontFamily: portalTheme.font.family.opensans,
        backgroundColor: portalTheme.color.basicColorWhite
      }
    }
  },
  onDeclinePreferences: () => {},
  onDeclineStatistics: () => {},
  onDeclineMarketing: () => {},
  onAccept: () => {},
  onAcceptPreferences: () => {},
  onAcceptStatistics: () => {},
  onAcceptMarketing: () => {},
  clickOnConfirm: () => {}
}

CookieBanner.propTypes = {
  className: PropTypes.string,
  theme: PropTypes.shape({
    components: PropTypes.shape({
      cookieBanner: PropTypes.shape({
        fontSize: PropTypes.string,
        fontWeight: PropTypes.string,
        backgroundColor: PropTypes.string,
        fontFamily: PropTypes.string
      })
    })
  }),
  bannerPrefix: PropTypes.string,
  message: PropTypes.any.isRequired,
  policyLink: PropTypes.string,
  checkboxHeaderLabel: PropTypes.string,
  privacyPolicyLinkText: PropTypes.string,
  necessaryOptionText: PropTypes.string,
  preferencesOptionText: PropTypes.string,
  statisticsOptionText: PropTypes.string,
  marketingOptionText: PropTypes.string,
  acceptButtonText: PropTypes.string,
  declineButtonText: PropTypes.string,
  showDeclineButton: PropTypes.bool,
  dismissOnScroll: PropTypes.bool,
  showPreferencesOption: PropTypes.bool,
  showStatisticsOption: PropTypes.bool,
  showMarketingOption: PropTypes.bool,
  onAccept: PropTypes.func,
  onAcceptPreferences: PropTypes.func,
  onAcceptStatistics: PropTypes.func,
  onAcceptMarketing: PropTypes.func,
  onDeclinePreferences: PropTypes.func,
  onDeclineStatistics: PropTypes.func,
  onDeclineMarketing: PropTypes.func,
  clickOnConfirm: PropTypes.func
}

export default CookieBanner

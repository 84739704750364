import styled from 'styled-components'

const SimpleNoteStyled = styled.div`
  background-color: ${({ theme }) =>
    theme.components.simpleNote.backgroundColor};

  border-left: 6px solid;
  border-left-color: ${({ noteStatus, theme }) => {
    switch (noteStatus) {
      case 'warning':
        return theme.components.simpleNote.warningMark.borderLeftColor
      case 'success':
        return theme.components.simpleNote.successMark.borderLeftColor
      case 'error':
        return theme.components.simpleNote.errorMark.borderLeftColor
      default:
        return '#fff'
    }
  }};
`

const TextStyled = styled.div`
  font-family: ${({ theme }) => theme.components.simpleNote.fontFamily};
  font-size: ${({ theme }) => theme.components.simpleNote.fontSize};
  font-weight: ${({ theme }) => theme.components.simpleNote.fontWeight};

  margin: 0;
  margin-left: 10px;
  padding: 3px 0;

  color: ${({ textStatus, theme }) =>
    textStatus === 'warning'
      ? theme.components.simpleNote.textWarningColor
      : '#333'};
`

export { SimpleNoteStyled, TextStyled }

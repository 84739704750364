import styled from 'styled-components'
import { allBorderBox } from '../../styles/helperStyles'

export const StyledLoadingProgressBar = styled.div`
  z-index: 1000;
  position: ${(props) => (props?.boxSpinner ? 'absolute' : 'fixed')};
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  ${(props) => (props?.boxSpinner ? 'justify-content: center' : '')};
  background-color: rgb(119, 118, 118, 0.1);
  ${(props) => props?.theme?.components?.loadingProgressBar?.additionalStyles}
  ${allBorderBox}
`

export const StyledOverlappingItem = styled.div`
  position: absolute;
  width: 100%;
  display: flex;
  align-items: ${(props) => (props?.boxSpinner ? 'center' : 'normal')};
  justify-content: center;
  height: 100%;
`

export const StyledOverlappingItemWrapper = styled.div`
  position: relative;
`

import styled from 'styled-components'
import {
  unstyledList,
  allBorderBox,
  containerFluidStyles
} from '../../styles/helperStyles'

export const MobilePullDownStyled = styled.nav`
  user-select: none;
  font-family: ${(props) => props.theme.components.mobilePullDown.fontFamily};
  font-size: ${(props) => props?.theme.components.mobilePullDown.fontSize};
  font-weight: ${(props) => props?.theme.components.mobilePullDown.fontWeight};
  color: ${(props) => props?.theme.components.mobilePullDown.color};
  border-bottom: 1px solid
    ${(props) => props.theme.components.mobilePullDown.borderBottomColor};

  .mobile-pull-down-icon-container {
    text-align: right;
    display: flex;
    align-items: center;
  }

  ul {
    flex-direction: column;
    font-family: ${(props) =>
      props.theme.components.mobilePullDown.mobilePullDownValuesRow.fontFamily};
    font-size: ${(props) =>
      props.theme.components.mobilePullDown.mobilePullDownValuesRow.fontSize};
    font-weight: ${(props) =>
      props.theme.components.mobilePullDown.mobilePullDownValuesRow.fontWeight};
    transition: max-height 0.2s ease;
    max-height: 0;
    overflow: hidden;

    li {
      &:last-child {
        margin-bottom: 20px;
      }
      margin-top: 20px;
      padding-left: 1.5em !important;
      text-align: center;
    }

    &.open {
      max-height: 500px;
    }
    ${unstyledList}
  }
  ${allBorderBox}
  ${containerFluidStyles}
  ${(props) => props?.theme?.components?.mobilePullDown?.additionalStyles}
`

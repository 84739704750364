import styled from 'styled-components'
import { portalTheme } from '../../styles/PortalTheme'
import { Col, Row, Container } from 'react-grid-system'

const ParagraphInfoContainerStyled = styled(Container)`
  margin-bottom: ${(props) =>
    props?.theme?.components?.paragraphInfo?.marginBottom || '2em'};
  padding-top: ${(props) =>
    props?.theme?.components?.paragraphInfo?.paddingTop || '1em'};
  padding-bottom: ${(props) =>
    props?.theme?.components?.paragraphInfo?.paddingBottom || '1em'};
  background-color: ${(props) =>
    props?.theme?.components?.paragraphInfo?.backgroundColor ||
    portalTheme.color.basicColorWhite};
  font-weight: ${(props) =>
    props?.theme?.components?.paragraphInfo?.fontWeight ||
    portalTheme.font.weight.regular};
  font-size: ${(props) =>
    props?.theme?.components?.paragraphInfo?.fontSize ||
    portalTheme.font.size.headingTiny};
  font-family: ${(props) =>
    props?.theme?.components?.paragraphInfo?.fontFamily ||
    portalTheme.font.family.opensans};
`

const TitleRowStyled = styled(Row)`
  font-weight: ${(props) =>
    props?.theme?.components?.paragraphInfo?.titleRow?.fontWeight ||
    portalTheme.font.weight.bold};
  background-color: ${(props) =>
    props?.theme?.components?.paragraphInfo?.titleRow?.backgroundColor ||
    portalTheme.color.basicColorWhite};
  border-bottom: ${(props) =>
      props?.theme?.components?.paragraphInfo?.titleRow?.borderBottom ||
      portalTheme.color.basicColorAccentRed}
    solid 2px;
  padding-bottom: ${(props) =>
    props?.theme?.components?.paragraphInfo?.titleRow?.paddingBottom ||
    '0.5em'};
  padding-left: ${(props) =>
    props?.theme?.components?.paragraphInfo?.titleRow?.paddingLeft || '0px'};
  padding-right: ${(props) =>
    props?.theme?.components?.paragraphInfo?.titleRow?.paddingRight || '0px'};
  font-size: ${(props) =>
    props?.theme?.components?.paragraphInfo?.titleRow?.fontSize ||
    portalTheme.font.size.headingTiny};
  font-family: ${(props) =>
    props?.theme?.components?.paragraphInfo?.titleRow?.fontFamily ||
    portalTheme.font.family.quicksand};
  color: ${(props) =>
    props?.theme?.components?.paragraphInfo?.titleRow?.color ||
    portalTheme.color.basicColorBlack};
`

const ParagraphBodyContainerStyled = styled(Container)`
  font-size: ${(props) =>
    props?.theme?.components?.paragraphInfo?.paragraphBodyContainer?.fontSize ||
    portalTheme.font.size.bodySmall};
  font-weight: ${(props) =>
    props?.theme?.components?.paragraphInfo?.paragraphBodyContainer
      ?.fontWeight || portalTheme.font.weight.regular};
  padding-top: ${(props) =>
    props?.theme?.components?.paragraphInfo?.paragraphBodyContainer
      ?.paddingTop || '1em'};
  padding-left: ${(props) =>
    props?.theme?.components?.paragraphInfo?.paragraphBodyContainer
      ?.paddingLeft || '2em'};
`

const ParagraphRowStyled = styled(Row)`
  font-size: ${(props) =>
    props?.theme?.components?.paragraphInfo?.paragraphRow?.fontSize || '1.1em'};
`

const ParagraphNumberColStyled = styled(Col)`
  padding-right: ${(props) =>
    props?.theme?.components?.paragraphInfo?.paragraphNumberCol?.paddingRight ||
    '0.5em'} !important;

  &:before {
    padding-right: ${(props) =>
      props?.theme?.components?.paragraphInfo?.paragraphNumberCol
        ?.paddingRight || '0.2em'};
    content: '\\A7';
  }
`

const ParagraphNameColStyled = styled(Col)`
  padding-right: ${(props) =>
    props?.theme?.components?.paragraphInfo?.paragraphNameCol?.paddingRight ||
    '0.5em'} !important;
  font-weight: ${(props) =>
    props?.theme?.components?.paragraphInfo?.paragraphNameCol?.fontWeight ||
    portalTheme.font.weight.bold};
`

const ParagraphSectionRowStyled = styled(Row)`
  padding-top: ${(props) =>
    props?.theme?.components?.paragraphInfo?.paragraphSectionRow?.paddingTop ||
    '0.3em'} !important;
  padding-left: ${(props) =>
    props?.theme?.components?.paragraphInfo?.paragraphSectionRow?.paddingLeft ||
    '1em'} !important;
`

const ParagraphSectionNumberColStyled = styled(Col)`
  padding-right: ${(props) =>
    props?.theme?.components?.paragraphInfo?.paragraphSectionNumberCol
      ?.paddingTop || '0.5em'} !important;
`

const ParagraphSectionNameColStyled = styled(Col)`
  font-weight: ${(props) =>
    props?.theme?.components?.paragraphInfo?.paragraphSectionNameCol
      ?.fontWeight || portalTheme.font.weight.bold};
`

const ParagraphSectionTextColStyled = styled(Col)`
  white-space: ${(props) =>
    props?.theme?.components?.paragraphInfo?.paragraphSectionTextSpan
      ?.whiteSpace || 'pre-line'} !important;
`

const ParagraphFooterContainerStyled = styled(Container)`
  margin-top: ${(props) =>
    props?.theme?.components?.paragraphInfo?.paragraphFooterContainer
      ?.marginTop || '1em'} !important;
  font-size: ${(props) =>
    props?.theme?.components?.paragraphInfo?.paragraphFooterContainer
      ?.fontSize || '0.5em'};
  color: ${(props) =>
    props?.theme?.components?.paragraphInfo?.paragraphFooterContainer
      ?.fontSize || portalTheme.color.basicColorDarkGrey1};
`

export {
  ParagraphInfoContainerStyled,
  TitleRowStyled,
  ParagraphBodyContainerStyled,
  ParagraphRowStyled,
  ParagraphNumberColStyled,
  ParagraphNameColStyled,
  ParagraphSectionRowStyled,
  ParagraphSectionNumberColStyled,
  ParagraphSectionNameColStyled,
  ParagraphSectionTextColStyled,
  ParagraphFooterContainerStyled
}

import React, { useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Col, Row } from 'react-grid-system'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'

import { ButtonIcon } from '../Button/'

import {
  ButtonCloseContainer,
  SimpleModalContainer,
  SimpleModalContent
} from './SimpleModal.styles'

const SimpleModal = (props) => {
  const {
    isOpen = false,
    handleModalClose,
    showButtonClose = true,
    closeOnOutsideClick = true,
    title = '',
    children,
    id = 'modal-container',
    className,
    ...rest
  } = props

  const handleOnEscKeyPress = useCallback(
    (e) => {
      if (e.keyCode === 27) {
        handleModalClose()
      }
    },
    [handleModalClose]
  )

  const handleOnOutsideClick = useCallback(
    (e) => {
      const isClickOutside = e.target === document.getElementById(id)

      if (closeOnOutsideClick && isClickOutside) {
        handleModalClose()
      }
    },
    [handleModalClose, closeOnOutsideClick]
  )

  useEffect(() => {
    document.addEventListener('keydown', handleOnEscKeyPress, false)
    document.addEventListener('click', handleOnOutsideClick, false)

    return () => {
      document.removeEventListener('keydown', handleOnEscKeyPress, false)
      document.removeEventListener('click', handleOnOutsideClick, false)
    }
  }, [handleOnEscKeyPress, handleOnOutsideClick])

  useEffect(() => {
    const targetElement = document.querySelector('#' + id)
    if (isOpen) {
      disableBodyScroll(targetElement)
    } else {
      enableBodyScroll(targetElement)
    }
    return () => {
      enableBodyScroll(targetElement)
    }
  }, [isOpen])

  return (
    <SimpleModalContainer
      isOpen={isOpen}
      role='dialog'
      onClick={handleOnOutsideClick}
      id={id}
      className={className}
      data-simple-modal=''
    >
      <SimpleModalContent data-simple-modal-content='' {...rest}>
        {title ? (
          <Row
            justify='between'
            nogutter
            style={{ width: '100%' }}
            data-simple-modal-title-row=''
          >
            <Col xs={1} sm={1} md={1} lg={1} />
            <Col
              xs={10}
              sm={10}
              md={10}
              lg={10}
              style={{ textAlign: 'center' }}
            >
              {title && title}
            </Col>
            <Col xs={1} sm={1} md={1} lg={1}>
              {showButtonClose && (
                <ButtonCloseContainer data-simple-modal-button-close=''>
                  <ButtonIcon
                    handleClick={handleModalClose}
                    icon='close'
                    data-test-id='button-close'
                  />
                </ButtonCloseContainer>
              )}
            </Col>
          </Row>
        ) : (
          showButtonClose && (
            <Row justify='end' align='end'>
              <Col data-simple-modal-button-close='' xs='content'>
                <ButtonIcon
                  handleClick={handleModalClose}
                  icon='close'
                  data-test-id='button-close'
                />
              </Col>
            </Row>
          )
        )}
        {children}
      </SimpleModalContent>
    </SimpleModalContainer>
  )
}

export default SimpleModal

SimpleModal.propTypes = {
  isOpen: PropTypes.bool,
  handleModalClose: PropTypes.func.isRequired,
  showButtonClose: PropTypes.bool,
  closeOnOutsideClick: PropTypes.bool,
  title: PropTypes.any,
  id: PropTypes.string
}

import React from 'react'
import CookieOption from './CookieOption'
import styled from 'styled-components'
import { Col, Row, Container } from 'react-grid-system'
import { ButtonLink, ButtonPrimary } from '../Button/'
import SimpleLink from '../SimpleLink/SimpleLink'

const CookieBannerContentStyled = styled.div`
  background: ${(props) => props.theme.components.cookieBanner.backgroundColor};
  font-family: ${(props) => props.theme.components.cookieBanner.fontFamily};
  font-size: ${(props) => props.theme.components.cookieBanner.fontSize};
  font-weight: ${(props) => props.theme.components.cookieBanner.fontWeight};
  opacity: 0.95;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.5);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100000;

  .cookie-banner-policy {
    font-size: ${(props) => props.theme.components.cookieBanner.fontSize};
  }

  .cookie-banner-container-row {
    padding-bottom: 1rem;
    padding-top: 1rem;
  }

  .cookie-banner-msg {
    padding-bottom: 0.25rem;
    padding-top: 0.25rem;
  }

  .cookie-banner-controls {
  }

  .cookie-banner-select-pane {
  }

  .cookie-banner-select-pane-header-label {
    padding-bottom: 0.25rem;
    padding-top: 0.25rem;
    text-align: right;

    display: flex;
  }

  .cookie-banner-select-pane-checkboxes-wrapper {
    padding-bottom: 0.25rem;
    padding-top: 0.25rem;
  }

  .cookie-banner-decline-btn-wrapper {
    padding-bottom: 0.25rem;
    padding-top: 0.25rem;
    padding-left: 0 !important;
    padding-right: 0 !important;
    text-align: right;

    display: flex;
    justify-content: center;

    .cookie-banner-decline-btn {
      padding: 0 1rem !important;
    }
  }

  .cookie-banner-accept-btn-wrapper {
    padding-bottom: 0.25rem;
    padding-top: 0.25rem;
    padding-left: 0 !important;
    padding-right: 0 !important;

    display: flex;
    justify-content: center;

    .cookie-banner-accept-btn {
    }
  }

  .cookie-banner-option-wrapper {
    padding-bottom: 0.25rem;
  }
`

const CookieBannerContent = (props) => {
  const {
    className = '',
    message = 'No text',
    policyLink = '/#',
    privacyPolicyLinkText = 'Privacy Policy',
    necessaryOptionText = 'Necessary',
    preferencesOptionText = 'Preferences',
    statisticsOptionText = 'Statistics',
    marketingOptionText = 'Marketing',
    checkboxHeaderLabel = '',
    showDeclineButton = false,
    acceptButtonText = 'Accept',
    declineButtonText = 'Decline',
    showPreferencesOption = true,
    showStatisticsOption = true,
    showMarketingOption = true,
    CustomCheckbox = false,
    initCheckPref = false,
    initCheckStats = false,
    onTogglePreferencesCookies = Function,
    onToggleStatisticsCookies = Function,
    onToggleMarketingCookies = Function,
    onDecline = Function,
    onConfirm = Function
  } = props

  return (
    <CookieBannerContentStyled theme={props.theme} className={` ${className}`}>
      <Container>
        <Row className='cookie-banner-container-row'>
          <Col sm={12} md={6} lg={6} className='cookie-banner-msg'>
            <span key='cookie-banner-msg'>{message}</span>
            <br />
            <SimpleLink
              theme={{
                components: {
                  simpleLink: {
                    fontSize: '14px'
                  }
                }
              }}
              key='cookie-banner-policy'
              url={policyLink}
              className='cookie-banner-policy'
              label={privacyPolicyLinkText}
            />
          </Col>

          <Col sm={12} md={6} lg={6} className='cookie-banner-controls'>
            <Row className='cookie-banner-select-pane'>
              <Col
                sm={6}
                md={6}
                lg={6}
                className='cookie-banner-select-pane-header-label'
              >
                {checkboxHeaderLabel}
              </Col>

              <Col
                sm={6}
                md={6}
                lg={6}
                className='cookie-banner-select-pane-checkboxes-wrapper'
              >
                <CookieOption
                  key='check-required-cookies'
                  id='check-required-cookies'
                  text={necessaryOptionText}
                  CustomCheckbox={CustomCheckbox}
                  disabled
                  checked
                />

                {showPreferencesOption && (
                  <CookieOption
                    key='check-preferences-cookies'
                    id='check-preferences-cookies'
                    text={preferencesOptionText}
                    onChange={onTogglePreferencesCookies}
                    CustomCheckbox={CustomCheckbox}
                    checked={initCheckPref}
                  />
                )}

                {showStatisticsOption && (
                  <CookieOption
                    key='check-statistics-cookies'
                    id='check-statistics-cookies'
                    text={statisticsOptionText}
                    onChange={onToggleStatisticsCookies}
                    CustomCheckbox={CustomCheckbox}
                    checked={initCheckStats}
                  />
                )}

                {showMarketingOption && (
                  <CookieOption
                    id='check-marketing-cookies'
                    text={marketingOptionText}
                    onChange={onToggleMarketingCookies}
                  />
                )}
              </Col>

              <Col
                sm={6}
                md={6}
                lg={6}
                className='cookie-banner-decline-btn-wrapper'
              >
                {showDeclineButton && (
                  <ButtonLink
                    type='button'
                    className='cookie-banner-decline-btn'
                    onClick={() => onDecline()}
                  >
                    <span>{declineButtonText}</span>
                  </ButtonLink>
                )}
              </Col>

              <Col
                sm={6}
                md={6}
                lg={6}
                className='cookie-banner-accept-btn-wrapper'
              >
                <ButtonPrimary
                  type='button'
                  className='cookie-banner-accept-btn'
                  onClick={() => onConfirm()}
                >
                  <span>{acceptButtonText}</span>
                </ButtonPrimary>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </CookieBannerContentStyled>
  )
}

export default CookieBannerContent

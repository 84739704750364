import styled, { css } from 'styled-components'

import { portalTheme } from '../../../styles/PortalTheme'

const isHiddenCSS = css`
  visibility: hidden;
  opacity: 0;
`

const ButtonStyled = styled.button`
  display: inline-flex;

  visibility: visible;
  opacity: 1;

  background-color: transparent;
  border: none;
  cursor: pointer;

  padding: 0;
  margin: 0;

  transition: visibility 450ms, opacity 450ms;

  &:focus {
    outline: none;
  }

  &:hover,
  &:focus {
    color: ${portalTheme.color.linkColorPrimaryHover};
  }

  ${(props) => props.isHidden && isHiddenCSS}
`

export { ButtonStyled }

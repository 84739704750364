import React from 'react'
import PropTypes from 'prop-types'
import {
  ContainerStyles,
  FillerStyles,
  LabelStyles
} from './ProgressBar.styles'

const ProgressBar = (props) => {
  const { theme, completed } = props
  return (
    <ContainerStyles theme={theme}>
      <FillerStyles theme={theme} completed={completed}>
        <LabelStyles theme={theme}>{`${completed}%`}</LabelStyles>
      </FillerStyles>
    </ContainerStyles>
  )
}

ProgressBar.propTypes = {
  completed: PropTypes.any,
  theme: PropTypes.shape({
    components: PropTypes.shape({
      progressBar: PropTypes.shape({
        /**
         * Has to be a { css } oject from styled-components
         */
        additionalStyles: PropTypes.array,
        fillerStyles: PropTypes.shape({
          backgroundColor: PropTypes.string
        }),
        labelStyles: PropTypes.shape({
          color: PropTypes.string,
          fontWeight: PropTypes.string,
          fontSize: PropTypes.string,
          fontFamily: PropTypes.string,
          paddingTop: PropTypes.string,
          paddingBottom: PropTypes.string,
          paddingLeft: PropTypes.string,
          paddingRight: PropTypes.string
        }),
        backgroundColor: PropTypes.string,
        borderRadius: PropTypes.string,
        margin: PropTypes.string
      })
    })
  })
}

export default ProgressBar

import React from 'react'
import { Col, Row } from 'react-grid-system'
import styled from 'styled-components'
import { portalTheme } from '../../styles/PortalTheme'
import {
  allBorderBox,
  containerFluidStyles,
  unstyledList
} from '../../styles/helperStyles'
import PropTypes from 'prop-types'

const SimpleListStyled = styled.div`
  color: ${(props) =>
    props?.theme?.components?.simpleList?.color ||
    portalTheme.color.basicColorWhite};
  .header {
    font-family: ${(props) =>
      props?.theme?.components?.simpleList?.header?.fontFamily ||
      portalTheme.font.family.quicksand};
    font-size: ${(props) =>
      props?.theme?.components?.simpleList?.header?.fontSize || '20px'};
    font-weight: ${(props) =>
      props?.theme?.components?.simpleList?.header?.fontWeight ||
      portalTheme.font.weight.bold};
    padding-bottom: 20px;
  }

  ul {
    ${unstyledList}
  }

  .value {
    font-size: ${(props) =>
      props?.theme?.components?.simpleList?.value?.fontSize || '14px'};
    padding-bottom: 10px;
  }
  ${allBorderBox}
  ${containerFluidStyles}
  ${(props) => props?.theme?.components?.simpleList?.additionalStyles}
`

const SimpleList = (props) => {
  return (
    <SimpleListStyled theme={props.theme}>
      <Row component='nav'>
        {props.label && (
          <Col component='h3' className='header' lg={12}>
            {props.label}
          </Col>
        )}
        <ul>
          {props.values.map((value, i) => (
            <Col key={props.label + i} as='li' className='value' lg={12}>
              {value}
            </Col>
          ))}
        </ul>
      </Row>
    </SimpleListStyled>
  )
}

SimpleList.propTypes = {
  label: PropTypes.string.isRequired,
  values: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.element])
  ).isRequired,
  theme: PropTypes.shape({
    components: PropTypes.shape({
      /**
       * Has to be a { css } oject from styled-components
       */
      simpleList: PropTypes.shape({
        additionalStyles: PropTypes.array,
        header: PropTypes.shape({
          fontFamily: PropTypes.string,
          fontSize: PropTypes.string,
          color: PropTypes.string
        }),
        value: PropTypes.shape({
          color: PropTypes.string
        })
      })
    })
  })
}

export default SimpleList

import React from 'react'
import PropTypes from 'prop-types'

import Icon from '../../Icon/Icon'

import { ButtonStyled } from './ButtonIcon.styles'

const ButtonIcon = (props) => {
  const {
    handleClick,
    isActive,
    isHover,
    isHidden,
    type,
    icon,
    size,
    style,
    ...rest
  } = props

  return (
    <ButtonStyled
      onClick={handleClick}
      isActive={isActive}
      isHover={isHover}
      isHidden={isHidden}
      type={type}
      style={style}
    >
      <Icon icon={icon} size={size} {...rest} aria-hidden='true' />
    </ButtonStyled>
  )
}

export default ButtonIcon

ButtonIcon.propTypes = {
  handleClick: PropTypes.func,
  isActive: PropTypes.bool,
  isHover: PropTypes.bool,
  isHidden: PropTypes.bool,
  type: PropTypes.any,
  icon: PropTypes.string.isRequired,
  size: PropTypes.string
}

ButtonIcon.defaultProps = {
  size: '20px',
  isHidden: false
}

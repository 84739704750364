import styled from 'styled-components'

import { buttonStylesDefaultCss } from '../../../styles/helperStyles'

const StyledButtonLink = styled.button`
  font-size: ${(props) => props.theme.components.buttonLink.fontSize};
  font-family: ${(props) => props.theme.components.buttonLink.fontFamily};
  font-weight: ${(props) => props.theme.components.buttonLink.fontWeight};

  border: none;
  color: ${(props) => props.theme.components.buttonLink.color};

  &:hover,
  &:focus,
  &:active {
    border: none;
  }

  padding: 0;

  ${buttonStylesDefaultCss}
`

export { StyledButtonLink }

import styled, { css } from 'styled-components'

const ButtonFlagHeaderCSS = css`
  padding: 5px;
  background-color: transparent;
  border: none;
  width: 35px;

  &:hover,
  &:focus {
    &:active {
      outline: none;
    }

    outline: none;
  }

  ${({ isActive }) => isActive && isActiveButtonFlagHeaderCSS}
`

// CSS styles for an active ButtonFlag with mode of 'header'
const isActiveButtonFlagHeaderCSS = css`
  text-decoration: none;
  border-bottom: 5px solid;
  border-bottom-color: ${(props) =>
    props.theme.components.buttonFlag.headerMode.borderBottomColor};
  font-weight: 500;

  &.disable-border {
    &:focus {
      border: none;
      text-decoration: none;
      outline: none;
    }

    &:active {
      border: none;
      text-decoration: none;
      outline: none;
    }
  }
`

const ImgButtonFlagHeaderCSS = css``

// ----------------------------------------------------------

const ButtonFlagModalCSS = css`
  box-sizing: border-box;

  background-color: ${(props) =>
    props.theme.components.buttonFlag.modalMode.backgroundColor};
  border: 13px solid;
  border-color: white;
  border-radius: 50%;

  padding: 0;
  margin: 0.25em;
  margin-bottom: 2em;

  &:hover,
  &:active,
  &:focus {
    border-color: ${(props) =>
      props.theme.components.buttonFlag.modalMode.active.borderColor};
    outline: none;
  }

  display: flex;
  align-items: center;
  justify-content: center;

  ${({ isActive }) => isActive && isActiveButtonFlagModalCSS}
`

// CSS styles for an active ButtonFlag with mode of 'modal'
const isActiveButtonFlagModalCSS = css`
  border-color: ${(props) =>
    props.theme.components.buttonFlag.modalMode.active.borderColor};
`

const ImgButtonFlagModalCSS = css`
  width: 50px;
  height: 50px;
`

// ----------------------------------------------------------

const ButtonFlagNormalCSS = css`
  box-sizing: border-box;

  background-color: ${(props) =>
    props.theme.components.buttonFlag.normalMode.backgroundColor};
  border: none;
  border-radius: 50%;

  padding: 0;
  margin: 0;

  &:hover,
  &:active,
  &:focus {
    outline: none;
  }

  display: flex;
  align-items: center;
  justify-content: center;

  ${({ isActive }) => isActive && isActiveButtonFlagNormalCSS}
`

// CSS styles for an active ButtonFlag with mode of 'normal'
const isActiveButtonFlagNormalCSS = css``

const ImgButtonFlagNormalCSS = css`
  width: 25px;
  height: 25px;
`

// ----------------------------------------------------------

const ButtonFlagStyled = styled.button`
  cursor: pointer;

  ${({ mode }) => {
    switch (mode) {
      case 'header':
        return ButtonFlagHeaderCSS
      case 'modal':
        return ButtonFlagModalCSS
      default:
        return ButtonFlagNormalCSS
    }
  }}
`

const ImgStyled = styled.img`
  ${({ mode }) => {
    switch (mode) {
      case 'header':
        return ImgButtonFlagHeaderCSS
      case 'modal':
        return ImgButtonFlagModalCSS
      default:
        return ImgButtonFlagNormalCSS
    }
  }}
`

export { ButtonFlagStyled, ImgStyled }

import styled from 'styled-components'

import {
  allBorderBox,
  buttonRemoveBrowserStylesCss
} from '../../../styles/helperStyles'

const StyledButtonSelector = styled.button`
  display: block;
  width: 100%;
  border: solid 2px
    ${(props) => props.theme.components.buttonSelector.borderColor};
  border-radius: 5px;
  /* height: 70px; */
  color: ${(props) => props.theme.components.buttonSelector.color};
  background-color: ${(props) =>
    props.theme.components.buttonSelector.backgroundColor};

  label {
    padding: 1rem;
    cursor: pointer;
    justify-content: center;
  }

  &.active {
    border-color: ${(props) =>
      props.theme.components.buttonSelector.active.borderColor};
    background-color: ${(props) =>
      props.theme.components.buttonSelector.active.backgroundColor};
    color: ${(props) => props.theme.components.buttonSelector.active.color};

    label {
      color: ${(props) => props.theme.components.buttonSelector.active.color};
    }
  }

  &:hover,
  &:focus {
    border-color: ${(props) =>
      props.theme.components.buttonSelector.active.borderColor};
  }

  ${allBorderBox}
  ${buttonRemoveBrowserStylesCss}
`

export { StyledButtonSelector }

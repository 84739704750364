import styled from 'styled-components'
import { portalTheme } from '../../styles/PortalTheme'

export const SimpleLabelStyled = styled.label`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  flex: 1;

  font-family: ${(props) =>
    props?.theme?.components?.simpleLabel?.fontFamily ||
    portalTheme.font.family.quicksand};
  font-size: ${(props) =>
    props?.theme?.components?.simpleLabel?.fontSize || '14px'};
  font-weight: ${(props) =>
    props?.theme?.components?.simpleLabel?.fontSize ||
    portalTheme.font.weight.regular};
  color: ${(props) =>
    props?.theme?.components?.simpleLabel?.color ||
    portalTheme.color.basicColorBlack};
  user-select: none;

  ${(props) => props?.theme?.components?.simpleLabel?.additionalStyles}
`

import React, { useCallback, useState } from 'react'
import CustomCheckboxRadio from '../CustomCheckboxRadio/CustomCheckboxRadio'
import PropTypes from 'prop-types'

const CookieOption = (props) => {
  const [checked, setChecked] = useState(props.checked)
  const handleOnChange = useCallback(() => {
    const onChange = props.onChange
    const newValue = !checked
    setChecked(newValue)
    onChange(newValue)
  }, [checked, props.onChange])

  const { id = '', text = '', disabled = false } = props

  const isDisabled = !!disabled

  return (
    <div className='cookie-banner-option-wrapper'>
      <CustomCheckboxRadio
        theme={{
          components: {
            customCheckboxRadio: {
              icon: {
                fontSize: '14px'
              }
            }
          }
        }}
        type='checkbox'
        id={id}
        name={id}
        onChange={handleOnChange}
        isChecked={checked}
        isDisabled={isDisabled}
        labelElement={<div className='simple-label'>{text}</div>}
      />
    </div>
  )
}

CookieOption.defaultProps = {
  checked: false,
  onChange: () => {}
}

CookieOption.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func
}

export default CookieOption

import styled, { css } from 'styled-components'

import Spinner from '../../Spinner/Spinner'
import { buttonStylesDefaultCss } from '../../../styles/helperStyles'
import { portalTheme } from '../../../styles/PortalTheme'

export const StyledButtonPrimary = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  --button-primary-focus-color: #393939;
  --button-primary-active-color: #5c5c5c;

  font-size: 14px;
  font-family: ${portalTheme.font.family.opensans};
  font-weight: ${portalTheme.font.weight.regular};

  border: none;
  background-color: ${portalTheme.color.basicColorBlack};
  color: ${portalTheme.color.linkColorSecondary};

  &:hover {
    background-color: var(--button-primary-focus-color, #393939);
  }

  &:focus {
    outline: solid !important;
    //background-color: var(--button-primary-focus-color, #393939);
    //border-color: var(--button-primary-focus-color, #393939);
    //box-shadow: inset 0 0 0 1px var(--button-primary-focus-color, #393939),
    //  inset 0 0 0 2px #f4f4f4;
  }

  &:active {
    background-color: var(--button-primary-active-color, #5c5c5c);
  }

  ${(props) =>
    props.disabled &&
    css`
      border-style: solid;
      border-width: 1px;
      border-color: ${portalTheme.color.disabledColor} !important;
      background-color: ${portalTheme.color.basicColorWhite} !important;
      color: ${portalTheme.color.disabledColor} !important;
      cursor: default !important;
    `}

  ${buttonStylesDefaultCss}
`

export const Content = styled.div`
  visibility: visible;
  opacity: 1;

  ${(props) =>
    props.hide &&
    css`
      visibility: hidden;
      opacity: 0;
    `}
`

export const StyledSpinner = styled(Spinner)`
  position: absolute;
  width: 30px;
  height: 30px;

  border-color: rgb(210, 175, 173, 0.3);
  border-top-color: rgba(232, 34, 27, 0.8);
`

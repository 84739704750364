import React from 'react'
import styled from 'styled-components'

import SimpleModal from '../SimpleModal/SimpleModal'
import SimpleNote from '../SimpleNote/SimpleNote'
import PropTypes from 'prop-types'
import SimpleLink from '../SimpleLink/SimpleLink'
import { ButtonPrimary } from '../Button'

const ModalHintTitle = styled.h4`
  font-family: sans-serif;
  font-size: 16px;
  margin: 0;
`

const ModalHint = (props) => {
  const {
    closeOnOutsideClick,
    isOpen,
    handleModalClose,
    message,
    buttonCloseMessage,
    title,
    url,
    showButtonClose = false,
    primaryButton
  } = props

  const renderLinkOrButton = () => {
    if (typeof primaryButton === 'object' && primaryButton.usePrimaryButton) {
      return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <ButtonPrimary
            style={{ width: 'auto' }}
            onClick={(e) => {
              e.preventDefault()
              handleModalClose()
              if (typeof primaryButton.useFunction === 'function') {
                primaryButton.useFunction()
              }
            }}
          >
            {buttonCloseMessage}
          </ButtonPrimary>
        </div>
      )
    }
    return (
      <SimpleLink
        url={url}
        onClick={(e) => {
          e.preventDefault()
          handleModalClose()
        }}
        label={buttonCloseMessage}
      />
    )
  }

  return (
    <SimpleModal
      closeOnOutsideClick={closeOnOutsideClick}
      isOpen={isOpen}
      handleModalClose={handleModalClose}
      showButtonClose={showButtonClose}
    >
      <ModalHintTitle>{title}</ModalHintTitle>
      <br />
      <SimpleNote text={message} />
      <br />
      {renderLinkOrButton()}
    </SimpleModal>
  )
}

ModalHint.defaultProps = {
  closeOnOutsideClick: true,
  isOpen: false,
  url: ''
}

ModalHint.propTypes = {
  closeOnOutsideClick: PropTypes.bool,
  url: PropTypes.string,
  isOpen: PropTypes.bool,
  handleModalClose: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
  buttonCloseMessage: PropTypes.any.isRequired,
  title: PropTypes.string.isRequired
}

export default ModalHint

import React from 'react'
import {
  StyledLoadingProgressBar,
  StyledOverlappingItem
} from './LoadingProgressBar.styles'
import PropTypes from 'prop-types'
import Spinner from '../Spinner/Spinner'
import { ProgressBar } from '../../index'
import { css } from 'styled-components'

const LoadingProgressBar = (props) => {
  const additionalStyles = css`
    max-width: 160px;
  `
  const smallTheme = {
    components: {
      spinner: {
        /**
         * Has to be a { css } oject from styled-components
         */
        size: '15px',
        border: '2px'
      }
    }
  }

  const progressBarTheme = {
    components: {
      progressBar: {
        /**
         * Has to be a { css } oject from styled-components
         */
        additionalStyles: additionalStyles
      }
    }
  }
  return (
    <StyledLoadingProgressBar theme={props.theme} boxSpinner={props.boxSpinner}>
      <StyledOverlappingItem boxSpinner={props.boxSpinner}>
        <ProgressBar completed={props.complete} theme={progressBarTheme} />
      </StyledOverlappingItem>
      <StyledOverlappingItem boxSpinner={props.boxSpinner}>
        {props.complete < 100 && <Spinner theme={smallTheme} />}
      </StyledOverlappingItem>
    </StyledLoadingProgressBar>
  )
}

LoadingProgressBar.propTypes = {
  complete: PropTypes.any,
  boxSpinner: PropTypes.any,
  theme: PropTypes.shape({
    components: PropTypes.shape({
      loadingProgressBar: PropTypes.shape({
        /**
         * Has to be a { css } oject from styled-components
         */
        additionalStyles: PropTypes.array
      })
    })
  })
}

export default LoadingProgressBar

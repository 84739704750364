import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import pyrexxLogo from './pyrexx_logo.svg'

const LogoDefault = styled.img``

const LogoHeader = styled.img`
  box-sizing: border-box;

  max-height: 80px;
  height: 70%;
  width: auto;

  padding: 1rem 0;
`

const Logo = (props) => {
  const { logoSrc = pyrexxLogo, header } = props

  const LogoSelector = header ? LogoHeader : LogoDefault

  return (
    <LogoSelector
      src={logoSrc}
      alt='pyrexx-logo'
      title='Pyrexx GmbH'
      header={header}
    />
  )
}

export default Logo

Logo.propTypes = {
  logoSrc: PropTypes.string,
  header: PropTypes.bool
}

Logo.defaultProps = {
  header: false
}

import React from 'react'
import PropTypes from 'prop-types'

import { StyledButtonLink } from './ButtonLink.styles'
import { portalTheme } from '../../../styles/PortalTheme'

const ButtonLink = (props) => (
  <StyledButtonLink theme={props.theme} {...props}>
    {props.children}
  </StyledButtonLink>
)

export default ButtonLink

ButtonLink.propTypes = {
  theme: PropTypes.shape({
    components: PropTypes.shape({
      buttonLink: PropTypes.shape({
        fontSize: PropTypes.string,
        fontWeight: PropTypes.string,
        color: PropTypes.string,
        backgroundColor: PropTypes.string,
        fontFamily: PropTypes.string
      })
    })
  })
}

ButtonLink.defaultProps = {
  theme: {
    components: {
      buttonLink: {
        fontSize: '14px',
        fontWeight: portalTheme.font.weight.regular,
        color: portalTheme.color.basicColorBlack,
        fontFamily: portalTheme.font.family.opensans
      }
    }
  }
}

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Card = styled.div`
  width: 100%;
`

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  box-sizing: border-box;

  /* TODO CHANGE */
  height: 10rem;

  background-color: #fff;
`

const TitleWithIcon = styled.div`
  display: flex;
  align-items: center;

  margin: 0.875em 0;

  * {
    margin-right: 0.75em;
  }

  opacity: 1;
`

const Title = styled.h3`
  font-weight: inherit;
  font-size: inherit;

  margin: 0;
  margin-right: 0.75em;

  ${(props) => props.isDisabled && 'opacity: 0.5'};
`

const TitleImg = styled.img`
  height: 30px;
`

const ServiceCard = (props) => {
  const {
    isDisabled = false,
    imgSrc = '',
    title = '',
    link = '',
    content = ''
  } = props

  return (
    <Card>
      <Header>
        <TitleWithIcon>
          {imgSrc && <TitleImg src={imgSrc} alt={''} />}
          <Title isDisabled={isDisabled}>{title}</Title>
        </TitleWithIcon>

        {isDisabled || link}
      </Header>

      <Content>{content}</Content>
    </Card>
  )
}

export default ServiceCard

ServiceCard.propTypes = {
  isDisabled: PropTypes.bool,
  imgSrc: PropTypes.any,
  title: PropTypes.string,
  link: PropTypes.any,
  content: PropTypes.any
}

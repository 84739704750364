import styled from 'styled-components'

const ToggleSwitchStyled = styled.label`
  position: relative;
  display: flex;
  height: 16px;
  width: 40px;
`

const CheckboxStyled = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
  display: none;
`

const SliderStyled = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background-color: #bbb;
  border-radius: 10px;
  cursor: pointer;

  -webkit-transition: 0.4s;
  transition: 0.4s;

  &:before {
    position: absolute;
    height: 24px;
    width: 24px;
    top: -5px;

    content: '';

    background-color: #eee;
    border-radius: 50%;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.23);

    -webkit-transition: 0.4s;
    transition: 0.4s;

    ${CheckboxStyled}:checked + & {
      -webkit-transform: translateX(16px);
      -ms-transform: translateX(16px);
      transform: translateX(16px);
    }
  }

  ${CheckboxStyled}:checked + & {
    background: rgb(140, 222, 150);
  }

  ${CheckboxStyled}:focus + & {
    /* ADD proper focus styles */
    /* box-shadow: 0 0 1px #2196f3; */
  }
`

export { ToggleSwitchStyled, SliderStyled, CheckboxStyled }

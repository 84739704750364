import styled from 'styled-components'

import { buttonStylesDefaultCss } from '../../../styles/helperStyles'
import Spinner from '../../Spinner/Spinner'

const StyledButtonSecondary = styled.button`
  font-size: ${(props) => props.theme.components.buttonSecondary.fontSize};
  font-family: ${(props) => props.theme.components.buttonSecondary.fontFamily};
  font-weight: ${(props) => props.theme.components.buttonSecondary.fontWeight};
  border-style: solid;
  border-width: 1px;

  ${(props) =>
    props.disabled
      ? `
          border-color: ${props.theme.components.buttonSecondary.disabled.borderColor} !important;
          background-color: ${props.theme.components.buttonSecondary.disabled.backgroundColor} !important;
          color: ${props.theme.components.buttonSecondary.disabled.color} !important;
          cursor: default !important;
        `
      : `
          border: 2px solid ${props.theme.components.buttonSecondary.borderColor};
          background-color: ${props.theme.components.buttonSecondary.backgroundColor};
          color: ${props.theme.components.buttonSecondary.color};
          &:hover,
          &:focus {
            border: 2px solid ${props.theme.components.buttonSecondary.focus.borderColor};
            background-color: ${props.theme.components.buttonSecondary.focus.backgroundColor};
          }

          &:active {
            color: ${props.theme.components.buttonSecondary.active.color};
            border: 2px solid ${props.theme.components.buttonSecondary.active.borderColor};
            background-color: ${props.theme.components.buttonSecondary.active.backgroundColor};
          }
        `}

  ${buttonStylesDefaultCss}
`

const StyledSpinner = styled(Spinner)`
  width: 30px;
  height: 30px;

  border-color: rgb(210, 175, 173, 0.3);
  border-top-color: rgba(232, 34, 27, 0.8);
`

export { StyledButtonSecondary, StyledSpinner }

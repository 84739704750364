import React, { useEffect, useRef } from 'react'
import { Col, Row } from 'react-grid-system'
import { portalTheme } from '../../styles/PortalTheme'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const MessageBoxStyled = styled(Row)`
  position: fixed;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
  font-family: ${(props) =>
    props?.theme?.components?.messageBox?.fontFamily ||
    portalTheme.font.family.quicksand};
  font-size: ${(props) =>
    props?.theme?.components?.messageBox?.fontSize || '14px'};
  font-weight: ${(props) =>
    props?.theme?.components?.messageBox?.fontWeight ||
    portalTheme.font.weight.regular};
  background-color: ${(props) =>
    props?.theme?.components?.messageBox?.backgroundColor ||
    portalTheme.color.basicColorWhite};
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
  transition: all 0.9s ease;
  opacity: 1;
  z-index: 10;

  &.hide {
    opacity: 0;
    display: none !important;
    z-index: 0;
  }

  .mark {
    padding: 0px !important;
    width: 10px !important;

    &.success-mark {
      background-color: ${(props) =>
        props?.theme?.components?.messageBox?.successMark?.backgroundColor ||
        portalTheme.color.successColor};
    }

    &.warning-mark {
      background-color: ${(props) =>
        props?.theme?.components?.messageBox?.warningMark?.backgroundColor ||
        portalTheme.color.warningColor};
    }

    &.error-mark {
      background-color: ${(props) =>
        props?.theme?.components?.messageBox?.errorMark?.backgroundColor ||
        portalTheme.color.errorColor};
    }
  }

  .message-container {
    padding: 10px;
  }
`

const MessageBox = (props) => {
  const timer = useRef(false) // now you can pass timer to another component

  useEffect(() => {
    const closeMessageBox = props.closeMessageBox
    if (!props.staticBox) {
      if (props.isVisible) {
        clearTimeout(timer.current)
        timer.current = setTimeout(() => closeMessageBox(), 2000)
      } else {
        clearTimeout(timer.current)
      }
    }
  }, [props.isVisible, props.updated, props.closeMessageBox, props.staticBox])

  return (
    <MessageBoxStyled
      style={props?.style}
      theme={props.theme}
      className={!props.isVisible && 'hide'}
    >
      <Col
        className={'mark ' + (props.statusColor && props.statusColor)}
        sm='content'
        md='content'
        lg='content'
      />
      <Col className='message-container'>
        <Row className='m-0'>
          <Col className='p-0 align-self-end'>{props.children}</Col>
          <Col className='p-0' sm='content' md='content' lg='content'>
            <button
              className='close pl-3'
              onClick={() => {
                props.closeMessageBox()
              }}
            >
              <span aria-hidden='true'>&times;</span>
            </button>
          </Col>
        </Row>
      </Col>
    </MessageBoxStyled>
  )
}

MessageBox.propTypes = {
  children: PropTypes.node.isRequired,
  closeMessageBox: PropTypes.func,
  isVisible: PropTypes.bool.isRequired,
  updated: PropTypes.instanceOf(Date),
  /**
   * To show the status bar, use following parameters.<br>
   * success: success-mark<br>
   * warning: warning-mark<br>
   * error: error-mark<br>
   */
  statusColor: PropTypes.string,
  theme: PropTypes.shape({
    components: PropTypes.shape({
      messageBox: PropTypes.shape({
        fontSize: PropTypes.string,
        fontWeight: PropTypes.string,
        backgroundColor: PropTypes.string,
        fontFamily: PropTypes.string,
        successMark: PropTypes.shape({
          backgroundColor: PropTypes.string
        }),
        warningMark: PropTypes.shape({
          backgroundColor: PropTypes.string
        }),
        errorMark: PropTypes.shape({
          backgroundColor: PropTypes.string
        })
      })
    })
  })
}

export default MessageBox

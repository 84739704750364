import styled from 'styled-components'
import { portalTheme } from '../../styles/PortalTheme'

const ContainerStyles = styled.div`
  ${(props) => props?.theme?.components?.progressBar?.additionalStyles}
  height: 20px;
  width: 100%;
  background-color: ${(props) =>
    props?.theme?.components?.progressBar?.backgroundColor ||
    'rgb(126,211,33, 0.15)'};
  border-radius: ${(props) =>
    props?.theme?.components?.progressBar?.borderRadius || '5px'};
  margin: ${(props) => props?.theme?.components?.progressBar?.margin || '0px'};
`

const FillerStyles = styled.div`
  transition: width 1s ease-in-out;
  height: 100%;
  width: ${(props) => props?.completed}%;
  background-color: ${(props) =>
    props?.theme?.components?.progressBar?.fillerStyles?.backgroundColor ||
    portalTheme.color.successColor};
  border-radius: inherit;
  text-align: right;
  line-height: normal;
`

const LabelStyles = styled.span`
  color: ${(props) =>
    props?.theme?.components?.progressBar?.labelStyles?.color ||
    portalTheme.color.basicColorBlack};
  font-weight: ${(props) =>
    props?.theme?.components?.progressBar?.labelStyles?.fontWeight ||
    portalTheme.font.weight.regular};
  font-size: ${(props) =>
    props?.theme?.components?.progressBar?.labelStyles?.fontSize ||
    portalTheme.font.size.headingTiny};
  font-family: ${(props) =>
    props?.theme?.components?.progressBar?.labelStyles?.fontFamily ||
    portalTheme.font.family.quicksand};
  padding-top: ${(props) =>
    props?.theme?.components?.progressBar?.labelStyles?.paddingTop || '2px'};
  padding-bottom: ${(props) =>
    props?.theme?.components?.progressBar?.labelStyles?.paddingBottom || '2px'};
  padding-left: ${(props) =>
    props?.theme?.components?.progressBar?.labelStyles?.paddingLeft || '3px'};
  padding-right: ${(props) =>
    props?.theme?.components?.progressBar?.labelStyles?.paddingRight || '3px'};
  line-height: normal;
`

export { ContainerStyles, FillerStyles, LabelStyles }

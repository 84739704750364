import React, { useCallback } from 'react'
import PropTypes from 'prop-types'

import { ButtonFlagStyled, ImgStyled } from './ButtonFlag.styles'
import { portalTheme } from '../../../styles/PortalTheme'

import srcSelector from './srcSelector'

const ButtonFlag = (props) => {
  const { language, isActive, changeLanguage, mode, handleClick, theme } = props

  const onClick = useCallback(() => {
    handleClick && handleClick()

    if (mode === 'modal') {
      return
    }

    changeLanguage && changeLanguage(language)
  }, [handleClick, changeLanguage, language, mode])

  return (
    <ButtonFlagStyled
      isActive={isActive}
      onClick={onClick}
      mode={mode}
      theme={theme}
    >
      <ImgStyled
        src={srcSelector(language, isActive, mode)}
        title={language}
        alt={language + ' language'}
        mode={mode}
        theme={theme}
      />
    </ButtonFlagStyled>
  )
}

export default ButtonFlag

ButtonFlag.propTypes = {
  language: PropTypes.string.isRequired,
  isActive: PropTypes.bool,
  changeLanguage: PropTypes.func,
  /**
   * Different ButtonFlag modes according to its use, either as a LangSwitcher, a LangSwitcherModal, or as a normal button.
   */
  mode: PropTypes.oneOf(['header', 'modal', 'normal']),
  /**
   * Optional helper function that executes after ButtonFlag click.
   */
  handleClick: PropTypes.func,
  theme: PropTypes.shape({
    components: PropTypes.shape({
      buttonFlag: PropTypes.shape({
        headerMode: PropTypes.shape({
          borderBottomColor: PropTypes.string
          // width: PropTypes.string
        }),
        modalMode: PropTypes.shape({
          backgroundColor: PropTypes.string,
          active: PropTypes.shape({
            borderColor: PropTypes.string
          })
          // border: PropTypes.string,
          // width: PropTypes.string,
        }),
        normalMode: PropTypes.shape({
          backgroundColor: PropTypes.string
          // width: PropTypes.string,
        })
      })
    })
  })
}

ButtonFlag.defaultProps = {
  isActive: false,
  mode: 'normal',
  theme: {
    components: {
      buttonFlag: {
        headerMode: {
          borderBottomColor: portalTheme.color.basicColorAccentRed
        },
        modalMode: {
          backgroundColor: portalTheme.color.langSwitcherGrey,
          active: {
            borderColor: portalTheme.color.langSwitcherDarkGrey
          }
        },
        normalMode: {
          backgroundColor: portalTheme.color.langSwitcherGrey
        }
      }
    }
  }
}
